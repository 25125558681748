<template>
	<div class="personal">
		<div class="personal_bg">
			<div class="info_top">
				<div class="avatar">
					<img width="65px" src="../../assets/img/ic_logo.png" alt="智岚博丰">
				</div>
				<div class="info_top_right">
					<div class="username">
						<!-- <span class="agent_name" v-show="pageform.agent_name != null">{{pageform.agent_name}}</span> -->
						<span>{{ username }}</span>
					</div>
					<!-- <div class="username"><span class="next_agent" v-if="pageform.next_agent != ''">({{pageform.next_agent}})</span></div> -->
					<div class="recommend" v-clipboard:copy="info.passport" v-clipboard:success="onCopy"
						v-clipboard:error="onError">
						<div>推荐码:{{ info.passport }}</div><img width="18px" src="../../assets/img/copy.png" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="personal_list">
			<div class="items">
				<router-link to="/Info/certification">
					<div class="info_item">
						<div class="left">
							<img width="36px" src="../../assets/img/ic_shimingrenzheng.png" alt="实名认证">
							<span>实名认证</span>
						</div>
						<div class="right">
							<!-- <span v-if="pageform.status==1">您还未实名,会影响部分功能使用</span>
							<span v-if="pageform.status==2">初审通过(有推荐码)</span>
							<span v-if="pageform.status==3">实名认证正在审核中</span>
							<span v-if="pageform.status==4">实名认证失败,请重新填写</span>
							<span v-if="pageform.status==5">身份已认证成功</span> -->
							<van-icon name="arrow" />
						</div>
					</div>
				</router-link>
				<router-link to="/Info/wallet">
					<div class="info_item">
						<div class="left">
							<img width="36px" src="../../assets/img/ic_jibenxinxi.png" alt="安全信息">
							<span>安全信息</span>
						</div>
						<div class="right">
							<van-icon name="arrow" />
						</div>
					</div>
				</router-link>
				<router-link to="/Info/password">
					<div class="info_item">
						<div class="left">
							<img width="36px" src="../../assets/img/ic_xiugaimima.png" alt="修改密码">
							<span>修改密码</span>
						</div>
						<div class="right">
							<van-icon name="arrow" />
						</div>
					</div>
				</router-link>
				<router-link to="/Info/power">
					<div class="info_item">
						<div class="left">
							<img width="36px" src="../../assets/img/ic_xitonggonggao.png" alt="电力订单">
							<span>电力订单</span>
						</div>
						<div class="right">
							<van-icon name="arrow" />
						</div>
					</div>
				</router-link>
			</div>
			<router-link to="/Announcement/list" style="display: none;">
				<div class="item">
					<div class="left">
						<img width="36px" src="../../assets/img/ic_xitonggonggao.png" alt="系统公告">
						<span>系统公告</span>
					</div>
					<div class="right">
						<van-icon name="arrow" />
					</div>
				</div>
			</router-link>

		</div>
		<div class="exit">
			<van-button color="#124DA5" @click="handleEdit">退出登录</van-button>
		</div>
	</div>
</template>

<script>
import { Admin, Personal } from "@/api/index";
import {
	getCookie,
	delCookie,
	setCookie
} from '@/assets/js/cookie.js'
export default {
	data() {
		return {
			username: '',
			info: {},
		};
	},
	created() {
		this.Info();
		if (typeof (this.$store.state.base.userName) == 'undefined' || this.$store.state.base.userName == '') {
			this.username = this.$store.state.base.passport
		} else {
			this.username = this.$store.state.base.userName
		}
	},
	methods: {
		//个人信息
		async Info() {
			let { status, data } = await Personal.info();
			if (status) {
				if (data.code == 0) {
					this.info = data.data;
				} else {
					this.$message.error(data.message)
				}
			}
		},
		async handleEdit() {
			let { status, data } = await Admin.logout();
			if (status) {
				if (data.code == 0) {
					localStorage.clear()
					let keys = document.cookie.match(/[^ =;]+(?=\=)/g);
					if (keys) {
						for (var i = keys.length; i--;) {
							document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();//清除当前域名下的,例如：m.kevis.com
							document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();//清除当前域名下的，例如 .m.kevis.com
							document.cookie = keys[i] + '=0;path=/;domain=aleopool.top;expires=' + new Date(0).toUTCString();//清除一级域名下的或指定的，例如 .kevis.com
						}
					}
					this.$router.replace('/login')
					this.$message.success('退出成功')
				} else {
					this.$message.error(data.message)
				}
			}
		},
		onCopy(e) { 　　 // 复制成功
			this.$message.success('复制成功')
		},
		onError(e) {　　 // 复制失败
			this.$message.success('复制失败')
		},
	},
}
</script>

<style lang="less">
.personal {
	.personal_bg {
		background: url(../../assets/img/ic_beijing5.png) no-repeat center left;
		background-color: #124DA5;
		width: 100vw;
		height: 48vw;
		position: relative;

		.info_top {
			display: flex;
			align-items: center;
			position: absolute;
			bottom: 10vw;
			left: 5vw;

			.avatar {
				img {
					display: block;
				}
			}

			.info_top_right {
				margin-left: 3vw;

				.username {
					font-size: 4.5vw;
					color: #ffffff;
					padding-bottom: 1vw;

					.agent_name {
						margin-right: 3vw;
					}
				}

				.next_agent {
					font-size: 3.5vw;
				}

				.recommend {
					display: flex;
					align-items: center;
					font-size: 3.5vw;
					color: #ffffff;
					padding-top: 1vw;

					img {
						display: block;
						margin-left: 2vw;
					}
				}
			}
		}
	}

	.personal_list {
		margin: 5vw 5vw;

		.item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
			padding: 3vw;
			border-radius: 3vw;
			margin-bottom: 2vw;

			.left {
				img {
					vertical-align: middle;
					margin-right: 2vw;
				}

				span {
					vertical-align: middle;
					font-size: 3.5vw;
				}
			}

			.right {
				color: #999999;
			}
		}

		.items {
			border-radius: 3vw;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
			padding: 0 3vw 0 3vw;
			margin-bottom: 3vw;
			margin-top: 20vw;

			.info_item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding-top: 4vw;
				padding-bottom: 4vw;
				margin-bottom: 2vw;
				border-bottom: 0.2vw solid #eee;

				.left {
					img {
						vertical-align: middle;
						margin-right: 2vw;
					}

					span {
						vertical-align: middle;
						font-size: 3.5vw;
					}
				}

				.right {
					color: #999999;

					.van-icon {
						vertical-align: middle;
					}

					span {
						font-size: 3.5vw;
						vertical-align: middle;
					}
				}
			}
		}
	}

	.exit {
		margin: 0 5vw 70vw;
		position: relative;

		.van-button {
			border-radius: 2vw;
			position: absolute;
			width: 90vw;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}
</style>
