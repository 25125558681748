<template>
	<div class="all_server">
		<div class="server_top">
			<div class="left">
				<div class="CN">业务中心</div>
				<div class="EN">The business center</div>
			</div>
			<div class="right">
				<div>
					<img width="44px" src="../../assets/img/ic_logo.png" alt="智岚博丰">
				</div>
			</div>
		</div>
		<div class="server_middle">
			<div class="server_box">
				<div class="my_server">
					<img width="24px" src="../../assets/img/ic_fuwuqi.png" alt="智岚博丰">
					<div class="number">{{ info.selfCount }}</div>
					<div class="text">PPS数量</div>
				</div>
				<div @click="showDialog()">
					<div class="insert">
						<van-icon name="add" color="#fff" size="7vw" />
						<div class="bottom">
							<span>购买PPS</span>
							<van-icon name="arrow" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="server_bottom">
			<van-tabs v-model="active" @change="tabchange">
				<van-tab title="全部" style="margin-bottom: 15vw;" name="">
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad()">
						<div class="server_list" v-for="(item, index) in ServerList">
							<div class="item">
								<div class="left">
									<div class="text">
										<div class="name">订单编号:{{ item.id }}</div>
										<div class="parameter">pps数:{{ item.ppsOrder }}</div>
										<van-button v-if="false" class="go_btn" color="#1989FA" size="mini"
											@click="goTrusteeship(item)"
											:disabled="item.trusteeshipFeeMonth >= item.trusteeshipMonth && item.status != 10">托管续期</van-button>
									</div>
								</div>
								<div class="right">
									<div v-if="false"
										:class="{ 'nums1': changeDate(item.trusteeshipFeeAt) && item.status == 10, 'nums2': !changeDate(item.trusteeshipFeeAt) || item.status != 10 }">
										托管时间:({{ item.trusteeshipFeeMonth }}/{{ item.trusteeshipMonth }})</div>
									<div class="status">
										<span style="color: #84CE61;" v-if="item.status == 10">审核通过</span>
										<span style="color: #1989FA;" v-if="item.status == 1">待审核</span>
										<span style="color: #FF8C8D;" v-if="item.status == 0">未支付</span>
										<span style="color: #1989FA;" v-if="item.status == -1">用户取消</span>
										<span style="color: #FF8C8D;" v-if="item.status == -10">审核不通过</span>
									</div>
									<van-button class="go_btn" color="#1989FA" size="mini"
										@click="goServer(item)">查看详情</van-button>
								</div>
							</div>
						</div>
					</van-list>
				</van-tab>
				<van-tab title="待支付" style="margin-bottom: 15vw;" name="0">
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad()">
						<div class="server_list" v-for="(item, index) in ServerList">
							<div class="item">
								<div class="left">
									<div class="text">
										<div class="name">订单编号:{{ item.id }}</div>
										<div class="parameter">pps数:{{ item.ppsOrder }}</div>
										<van-button class="go_btn" color="#1989FA" size="mini"
											@click="goTrusteeship(item)"
											:disabled="item.trusteeshipFeeMonth >= item.trusteeshipMonth && item.status != 10">托管续期</van-button>
									</div>
								</div>
								<div class="right">
									<div
										:class="{ 'nums1': changeDate(item.trusteeshipFeeAt) && item.status == 10, 'nums2': !changeDate(item.trusteeshipFeeAt) || item.status != 10 }">
										托管时间:({{ item.trusteeshipFeeMonth }}/{{ item.trusteeshipMonth }})</div>
									<div class="status">
										<span style="color: #84CE61;" v-if="item.status == 10">审核通过</span>
										<span style="color: #1989FA;" v-if="item.status == 1">待审核</span>
										<span style="color: #FF8C8D;" v-if="item.status == 0">未支付</span>
										<span style="color: #1989FA;" v-if="item.status == -1">用户取消</span>
										<span style="color: #FF8C8D;" v-if="item.status == -10">审核不通过</span>
									</div>
									<van-button class="go_btn" color="#1989FA" size="mini"
										@click="goServer(item)">查看详情</van-button>
								</div>
							</div>
						</div>
					</van-list>
				</van-tab>
				<van-tab title="待审核" style="margin-bottom: 15vw;" name="1">
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad()">
						<div class="server_list" v-for="(item, index) in ServerList">
							<div class="item">
								<div class="left">
									<div class="text">
										<div class="name">订单编号:{{ item.id }}</div>
										<div class="parameter">pps数:{{ item.ppsOrder }}</div>
										<van-button class="go_btn" color="#1989FA" size="mini"
											@click="goTrusteeship(item)"
											:disabled="item.trusteeshipFeeMonth >= item.trusteeshipMonth && item.status != 10">托管续期</van-button>
									</div>
								</div>
								<div class="right">
									<div
										:class="{ 'nums1': changeDate(item.trusteeshipFeeAt) && item.status == 10, 'nums2': !changeDate(item.trusteeshipFeeAt) || item.status != 10 }">
										托管时间:({{ item.trusteeshipFeeMonth }}/{{ item.trusteeshipMonth }})</div>
									<div class="status">
										<span style="color: #84CE61;" v-if="item.status == 10">审核通过</span>
										<span style="color: #1989FA;" v-if="item.status == 1">待审核</span>
										<span style="color: #FF8C8D;" v-if="item.status == 0">未支付</span>
										<span style="color: #1989FA;" v-if="item.status == -1">用户取消</span>
										<span style="color: #FF8C8D;" v-if="item.status == -10">审核不通过</span>
									</div>
									<van-button class="go_btn" color="#1989FA" size="mini"
										@click="goServer(item)">查看详情</van-button>
								</div>
							</div>
						</div>
					</van-list>
				</van-tab>
				<van-tab title="已完成" style="margin-bottom: 15vw;" name="10">
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad()">
						<div class="server_list" v-for="(item, index) in ServerList">
							<div class="item">
								<div class="left">
									<div class="text">
										<div class="name">订单编号:{{ item.id }}</div>
										<div class="parameter">pps数:{{ item.ppsOrder }}</div>
										<van-button class="go_btn" color="#1989FA" size="mini"
											@click="goTrusteeship(item)"
											:disabled="item.trusteeshipFeeMonth >= item.trusteeshipMonth && item.status != 10">托管续期</van-button>
									</div>

								</div>
								<div class="right">
									<div
										:class="{ 'nums1': changeDate(item.trusteeshipFeeAt) && item.status == 10, 'nums2': !changeDate(item.trusteeshipFeeAt) || item.status != 10 }">
										托管时间:({{ item.trusteeshipFeeMonth }}/{{ item.trusteeshipMonth }})</div>
									<div class="status">
										<span style="color: #84CE61;" v-if="item.status == 10">审核通过</span>
										<span style="color: #1989FA;" v-if="item.status == 1">待审核</span>
										<span style="color: #FF8C8D;" v-if="item.status == 0">未支付</span>
										<span style="color: #1989FA;" v-if="item.status == -1">用户取消</span>
										<span style="color: #FF8C8D;" v-if="item.status == -10">审核不通过</span>
									</div>
									<van-button class="go_btn" color="#1989FA" size="mini"
										@click="goServer(item)">查看详情</van-button>
								</div>
							</div>
						</div>
					</van-list>
				</van-tab>
			</van-tabs>
		</div>
		<!-- 合同 -->
		<van-dialog class="pact" v-model="show" title="《服务协议》" :showConfirmButton="false">
			<div class="contract_content">
				<div>
					尊敬的客户，欢迎您成为ALeo的一员，公司秉承“技术领先 服务至上”的理念，将在未来与您合作共赢，携手同行，基于合作事项提示如下：
				</div>
				<div>
					1、按照您的需求选择购买的套餐
				</div>
				<div style="display:none;">
					1 PPS 服务期限2年
				</div>
				<div>
					5 PPS 服务期限2年
				</div>
				<div>
					10PPS 服务期限3年
				</div>
				<div>
					100 PPS 服务期限永久
				</div>
				<div>
					支付购买款项后，将付款凭证作为附件，从后台点击购买，工作人员审核后生效。
				</div>
				<div>
					2、购买的套餐从审核成功后第10天分配收益，参与TEST3的客户服务开始时间视ALeo官方通知为准。
				</div>
				<div>
					3、服务周期内，客户需要每季度18元/PPS缴纳托管费，该费用需提前7天缴纳。因客户未能按时缴纳将会停止分配收益。
				</div>
				<div>
					4、客户购买的是ALeo的计算服务，不对照实体硬件，硬件设备所有权归属公司。
				</div>
				<div>
					5、每天收益的30%ALeo作为技术服务费归属公司，70%归属客户。
					<div>
						6、公司应该保障服务的持续性和稳定性，因不可控因素造成收益停止，公司不承担责任。除不可控因素外因公司运维原因造成的收益停止由公司负责。
					</div>
					<div>
						7、服务到期后是否可以续期以公司新的通知为准。
					</div>
					<div>
						8、其他未尽事宜最终解释权归属公司。
					</div>
					<div class="bottom">
						<div class="swit">
							<van-radio-group v-model="radio" direction="horizontal">
								<van-radio name="1">我已阅读并同意以上协议</van-radio>
							</van-radio-group>
						</div>
						<div class="agree">
							<van-button class="show_btn" color="#124DA5" @click="handleBuy()">去购买</van-button>
							<van-button class="show_btn" @click="Cancle()">取消</van-button>
						</div>
					</div>
				</div>
			</div>
		</van-dialog>
	</div>
</template>

<script>
import {
	Home,
	Server,
	Personal
} from "@/api/index";
export default {
	data() {
		return {
			radio: '',
			show: false,
			info: {},
			active: '',
			form: {
				coins: {}
			},
			ServerList: [],
			//刷新
			loading: false,
			finished: false,
			data: [],
			page: {
				current_page: 1,
				pagesize: 10
			},
			currentstatus: '',
			judge: 0
		};
	},
	created() {
		this.Info();
	},
	methods: {
		showDialog() {
			this.show = true;
		},
		Cancle() {
			this.show = false;
		},
		//跳转购买
		handleBuy() {
			if (this.radio == '') {
				this.$message.error("请先阅读并同意以上购买协议");
				return
			} else {
				this.$router.push('/Buy/first');
			}
		},
		//个人信息
		async Info() {
			let {
				status,
				data
			} = await Personal.info();
			if (status) {
				if (data.code == 0) {
					this.info = data.data;
				}
			}
		},
		//购买
		goServer(item) {
			this.$router.push({
				path: '/Server/detail',
				query: {
					id: item.id
				}
			})
		},
		//续期
		goTrusteeship(item) {
			this.$router.push({
				path: '/Server/trusteeship',
				query: {
					id: item.id,
					feedate: item.trusteeshipFeeMonth,
					date: item.trusteeshipMonth
				}
			})
		},
		async onLoad() {
			if (!this.finished) {
				let {
					status,
					data
				} = await Server.userlist({
					PageNum: this.page.current_page,
					status: this.currentstatus
				});
				if (status) {
					if (data.data.list === null) {
						this.finished = true;
					} else {
						this.ServerList.push(...data.data.list);
						if (this.ServerList.length == data.data.total || data.data.list === null) {
							this.finished = true;
						}
						this.page.current_page += 1
					}
				}
			}
			// 加载状态结束
			this.loading = false;
		},
		tabchange(name, title) {
			this.currentstatus = name
			this.ServerList = []
			this.page.current_page = 1
			this.finished = false
			this.loading = true
			this.onLoad()
		},
		//时间戳
		changeDate(val) {
			if (val != '') {
				let that = this;
				let date = new Date(val);
				let time1 = date.getTime() / 1000;
				let poor = time1 - 604800;
				let now = Date.parse(new Date()) / 1000;
				let judge = poor - now
				if (judge <= 0) {
					return false
				} else {
					return true
				}
			} else {
				let that = this;
				val = Date.parse(new Date()) / 1000
				let now = Date.parse(new Date()) / 1000;
				let judge = val - now
				if (judge <= 0) {
					return false
				} else {
					return true
				}
			}

		}
	}
};
</script>

<style lang="less">
.all_server {
	.pact {
		background-color: rgba(255, 255, 255, 0.9);
		overflow-y: auto;
		height: 80%;
		width: 76vw;
		padding: 5vw;
		font-size: 4vw;
		text-align: justify;
		line-height: 8vw;

		.bottom {
			.swit {
				display: flex;
				padding-top: 4vw;
				justify-content: space-between;

				.font {
					font-size: 3.5vw;
					width: 80%;
					line-height: 5vw;
				}
			}

			.agree {
				padding-top: 3vw;
				display: flex;
				justify-content: space-between;

				.show_btn {
					border-radius: 3vw;
					width: 30vw;
				}

				.van-button {}
			}
		}
	}

	.van-tabs__line {
		background-color: #124DA5;
	}

	.van-tabs__wrap {
		margin-bottom: 2vw;
	}

	.server_top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 5vw 5vw;

		.left {
			.CN {
				font-size: 6vw;
				color: #333333;
				font-weight: bold;
				padding-bottom: 1vw;
			}

			.EN {
				font-size: 3vw;
				color: #999999;
			}
		}
	}

	.server_middle {
		margin: 5vw 0 5vw 5vw;

		.server_box {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.my_server {
				background-image: linear-gradient(to right, #0860B0, #134BA2);
				border-radius: 3vw;
				height: 58vw;
				width: 47vw;
				padding: 8vw 5vw;
				box-sizing: border-box;

				.number {
					font-size: 6vw;
					color: #fff;
					padding-top: 18vw;
				}

				.text {
					font-size: 3vw;
					color: #fff;
					padding-top: 2vw;
				}
			}

			.insert {
				padding: 5vw 3vw;
				box-sizing: border-box;
				height: 35vw;
				width: 35vw;
				background-color: #26A6F4;
				border-top-left-radius: 3vw;
				border-bottom-left-radius: 3vw;

				.bottom {
					padding-top: 6vw;
					display: flex;
					align-items: center;
					font-size: 3vw;
					color: #ffffff;
				}
			}
		}
	}

	.server_bottom {
		margin: 5vw 5vw;
	}

	.server_list {
		.item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 3vw 0;
			border-bottom: 0.2vw solid #eee;

			.go_btn {
				margin-top: 2vw;
				border-radius: 1.5vw;
				padding: 5px 5px;
			}

			.left {
				display: flex;
				align-items: center;

				.text {
					margin-left: 2vw;

					.name {
						font-size: 4vw;
						font-weight: bold;
						color: #333333;
						padding-bottom: 1vw;
					}

					.parameter {
						font-size: 4vw;
						color: #999999;
						padding-top: 1vw;
					}
				}
			}

			.right {
				text-align: right;
				font-size: 3.5vw;

				.nums1 {
					font-weight: 4vw;
					color: #0860B0;
					padding-bottom: 1vw;
				}

				.nums2 {
					font-weight: 4vw;
					color: #FF8C8D;
					padding-bottom: 1vw;
				}

				.status {
					padding-top: 1vw;
				}
			}
		}
	}
}
</style>
