<template>
	<div class="first">
		<div class="first_title">
			<div class="CN">购买PPS</div>
			<div class="EN">Purchase PPS</div>
			<img width="40px" src="../../assets/img/ic_gou.png" alt="智岚博丰">
		</div>
		<div class="mine">
			<van-field readonly clickable label="选择矿池" v-model="pool_title" placeholder="选择数据中心"
				@click="showPicker = true" />
			<van-popup v-model="showPicker" round position="bottom">
				<van-picker show-toolbar :columns="columns" :default-index="0" @cancel="showPicker = false"
					@confirm="onConfirm" />
			</van-popup>
		</div>
		<div class="server_type">
			<div class="title">套餐类型</div>
		</div>
		<div class="package_box">
			<div class="package" v-for="(item,index) in PPSpackage">
				<div class="left">
					<div class="price">单价:￥{{item.ppsPrice}}</div>
					<div class="number">pps数量:{{item.ppsNumber}}</div>
				</div>
				<div class="right">
					<!-- <van-stepper v-model="item.ppsBuyNumber" :default-value="0" min="0" @change="changeNum(item)"/> -->
					<div class="price">年限:{{item.ppsValidite}}</div>
					<div class="number">已购:{{item.ppsBuyNumber}}</div>
				</div>
			</div>
		</div>
		<div class="first_item">
			<div class="left">pps数量</div>
			<div class="right">
				<van-stepper v-model="ppsBuyNumber" :default-value="5" setp="5" min="5" @change="changeNum(ppsBuyNumber)" />
			</div>
		</div>
		<div class="first_item">
			<div class="left">总金额</div>
			<div class="right"><span>{{amount}}元</span></div>
		</div>
		<div class="first_btn">
			<van-button color="#FF5722" :disabled='isDisabled' @click="submitForm">提交</van-button>
		</div>
	</div>
</template>

<script>
	import {
		Buy
	} from "@/api/index";
	export default {
		data() {
			return {
				isDisabled: false,
				pool_title: '',
				showPicker: false,
				use_package: 0,
				columns: [],
				form: {
					poolId: '1',
					ppsId: ''
				},
				total: {
					ppsbuynum: 0,
					ppsprice: 0
				},
				PPSpackage: [],
				kuangform: [],
				mechineform: [],
				mechine: {},
				sum: [],
				totalPrice: 0,
				commitForm:[],
				ppsBuyNumber:5,
				newArr:[],
				amount:11760,
			};
		},
		created() {
			this.Machinelist();
			this.PPSlist();
			
		},
		methods: {
			changeNum(val) {
				let that = this;
				let total = val;
				let currentValue = 0;
				let amount = 0;
				that.newArr.forEach(function(item){
					currentValue = Math.floor(total / item.ppsNumber);
					item.ppsBuyNumber = currentValue 
					total -= currentValue * item.ppsNumber;
					amount += currentValue * item.ppsPrice;
				})
				that.amount = amount;
			},
			//kangchi列表
			async Machinelist() {
				let {
					status,
					data
				} = await Buy.machinelist();
				if (status) {
					let that = this
					this.kuangform = data.data;
					this.kuangform.list.forEach(function(item, index) {
						if (index == 0) {
							that.pool_title = item.poolName;
							that.form.poolId = item.id;
						}
						let column = {
							text: item.poolName,
							pool_id: item.id,
							index: index
						}
						that.columns.push(column)
					})
				}
			},
			//pps列表
			async PPSlist() {
				let {
					status,
					data
				} = await Buy.ppslist({
					pool: this.form.poolId
				})
				if (status) {
					let that = this
					data.data.list.forEach(function(item, index) {
						that.PPSpackage.push({
							id: item.id,
							poolId: item.poolId,
							ppsName: item.ppsName,
							ppsNumber: item.ppsNumber,
							ppsPrice: item.ppsPrice,
							ppsValidite: item.ppsValidite,
							ppsValiditeDays: item.ppsValiditeDays,
							deliveryDays: item.deliveryDays,
							ppsBuyNumber: 5
						})
					})
					that.newArr = that.PPSpackage.reverse()
				}
			},
			onConfirm(value) {
				this.pool_title = value.text;
				this.form.poolId = value.pool_id;
				this.showPicker = false;
			},
			async submitForm() {
				if (this.form.poolId == '') {
					this.$message.error('请选择矿池')
					return
				}
				if (this.amount <= 0) {
					this.$message.error('金额需大于0')
					return
				}
				
				let {
					status,
					data
				} = await Buy.buy({
					poolId:this.form.poolId,
					ppsOrder:this.ppsBuyNumber,
					ppsAmount:this.amount
				});
				if (status) {
					if (data.code == 0) {
						this.goPay(data.data.orderId)
						this.isDisabled = true;
					} else {
						this.$message.error(data.message);
					}
				} else {
					this.$message.error('请求失败');
				}
			},
			goPay(id) {
				this.$router.push({
					path: "/Buy/second",
					query: {
						id: id,
					}
				})
			}
		},
		watch: {
			// PPSpackage: {
			// 	handler(newVal) {
			// 		let that = this;
			// 		that.sum = []
			// 		that.commitForm = []
			// 		newVal.forEach(function(item) {
			// 			let price = item.ppsBuyNumber * item.ppsPrice
			// 			that.sum.push(price)
			// 			that.commitForm.push(item)
			// 		})
			// 		this.totalPrice = this.sum.reduce((a, b) => a + b)
			// 	},
			// 	deep: true,
			// 	immediate: false,
			// }
		},
	};
</script>

<style lang="less">
	.first {
		.van-cell::after {
			border-bottom: 0;
		}

		.first_title {
			position: relative;

			.CN {
				text-align: center;
				margin-top: 10vw;
				font-size: 4vw;
				font-weight: bold;
				color: #333333;
			}

			.EN {
				margin-top: 2vw;
				margin-bottom: 2vw;
				text-align: center;
				font-size: 3vw;
				font-weight: bold;
				color: #333333;
			}

			img {
				position: absolute;
				right: 5vw;
				top: 0vw;
			}
		}
		.package {
			
			padding: 1vw 5vw;
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
			border-bottom: 1px solid #eeeeee;
			.left {
				font-size: 3.5vw;
				color: #999999;

				.price {}

				.number {
					padding-top: 4.5vw;
				}
			}

			.right {
				font-size: 3.5vw;
				color: #999999;
				.number {
					text-align: right;
					padding-top: 4.5vw;
				}

			}
		}
		.package_box{
			margin: 0 5vw;
			border-top: 1px solid #eeeeee;
			border-left: 1px solid #eeeeee;
			border-right: 1px solid #eeeeee;
		}
		.first_item {
			margin: 5vw 5vw;
			padding: 5vw;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			border-radius: 3vw;
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;

			.left {
				font-size: 3.5vw;
				color: #999999;
			}

			.right {
				span {
					color: #333333;
					font-size: 3.5vw;
				}
			}
		}

		.choose_item {
			margin: 5vw 5vw;
			padding: 5vw;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			border-radius: 3vw;
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;

			.left {
				font-size: 3.5vw;
				color: #999999;
			}

			.right {
				width: 70%;

				.van-radio {
					padding-top: 2vw;
				}

				.van-radio-group--horizontal {
					display: block;
				}

				.first .choose_item .right .van-radio {
					padding-top: 4vw !important;
				}
			}
		}

		.server_type {
			margin: 5vw 5vw;

			.title {
				color: #333333;
				font-size: 3.5vw;
				font-weight: bold;
			}

			.choose {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
			}
		}

		.mine {
			.van-field__control {
				border: 0.2vw solid #999999;
				border-radius: 2vw;
				padding: 1vw 2vw;
			}
		}

		.first_btn {
			margin: 5vw 5vw 20vw;

			.van-button {
				width: 90vw;
				border-radius: 2vw;
			}
		}
	}
</style>
