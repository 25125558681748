<template>
    <div class="all_server">
        <div class="server_top">
            <div class="left">
                <div class="CN">电力订单</div>
                <div class="EN">The Power Order</div>
            </div>
            <div class="right">
                <div>
                    <img width="44px" src="../../assets/img/ic_logo.png" alt="电力订单">
                </div>
            </div>
        </div>
        <div class="server_bottom">
            <van-tabs v-model="active" @change="tabchange">
                <van-tab title="全部" style="margin-bottom: 15vw;" name="">
                    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad()">
                        <div class="server_list" v-for="(item, index) in ServerList">
                            <div class="item">
                                <div class="left">
                                    <div class="text">
                                        <div class="name">订单编号:{{ item.id }}</div>
                                        <div class="parameter">pps数:{{ item.ppsCount }}</div>
                                    </div>
                                </div>
                                <div class="right">
                                    <div>订单金额:({{ item.amount }})</div>
                                    <div class="status">
                                        <span style="color: #84CE61;" v-if="item.status == 1">审核通过</span>
                                        <span style="color: #1989FA;" v-if="item.status == 0">待审核</span>
                                        <span style="color: #FF8C8D;" v-if="item.status == -1">审核不通过</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </van-tab>
                <van-tab title="待审核" style="margin-bottom: 15vw;" name="0">
                    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad()">
                        <div class="server_list" v-for="(item, index) in ServerList">
                            <div class="item">
                                <div class="left">
                                    <div class="text">
                                        <div class="name">订单编号:{{ item.id }}</div>
                                        <div class="parameter">pps数:{{ item.ppsCount }}</div>
                                    </div>
                                </div>
                                <div class="right">
                                    <div>订单金额:({{ item.amount }})</div>
                                    <div class="status">
                                        <span style="color: #84CE61;" v-if="item.status == 1">审核通过</span>
                                        <span style="color: #1989FA;" v-if="item.status == 0">待审核</span>
                                        <span style="color: #FF8C8D;" v-if="item.status == -1">审核不通过</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </van-tab>
                <van-tab title="审核通过" style="margin-bottom: 15vw;" name="1">
                    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad()">
                        <div class="server_list" v-for="(item, index) in ServerList">
                            <div class="item">
                                <div class="left">
                                    <div class="text">
                                        <div class="name">订单编号:{{ item.id }}</div>
                                        <div class="parameter">pps数:{{ item.ppsCount }}</div>
                                    </div>
                                </div>
                                <div class="right">
                                    <div>订单金额:({{ item.amount }})</div>
                                    <div class="status">
                                        <span style="color: #84CE61;" v-if="item.status == 1">审核通过</span>
                                        <span style="color: #1989FA;" v-if="item.status == 0">待审核</span>
                                        <span style="color: #FF8C8D;" v-if="item.status == -1">审核不通过</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </van-tab>
                <van-tab title="审核不通过" style="margin-bottom: 15vw;" name="-1">
                    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad()">
                        <div class="server_list" v-for="(item, index) in ServerList">
                            <div class="item">
                                <div class="left">
                                    <div class="text">
                                        <div class="name">订单编号:{{ item.id }}</div>
                                        <div class="parameter">pps数:{{ item.ppsCount }}</div>
                                    </div>

                                </div>
                                <div class="right">
                                    <div>订单金额:({{ item.amount }})</div>
                                    <div class="status">
                                        <span style="color: #84CE61;" v-if="item.status == 1">审核通过</span>
                                        <span style="color: #1989FA;" v-if="item.status == 0">待审核</span>
                                        <span style="color: #FF8C8D;" v-if="item.status == -1">审核不通过</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
import {
    Home,
    Server,
    Personal
} from "@/api/index";
export default {
    data() {
        return {
            radio: '',
            info: {},
            active: '',
            form: {
                coins: {}
            },
            ServerList: [],
            //刷新
            loading: false,
            finished: false,
            data: [],
            page: {
                current_page: 1,
                pagesize: 10
            },
            currentstatus: '',
            judge: 0
        };
    },
    created() {
        this.Info();
    },
    methods: {
        showDialog() {
            this.show = true;
        },
        Cancle() {
            this.show = false;
        },
        //个人信息
        async Info() {
            let {
                status,
                data
            } = await Personal.info();
            if (status) {
                if (data.code == 0) {
                    this.info = data.data;
                }
            }
        },
        async onLoad() {
            if (!this.finished) {
                let {
                    status,
                    data
                } = await Server.trusteeshiplist({
                    PageNum: this.page.current_page,
                    status: this.currentstatus
                });
                if (status) {
                    if (data.data.list === null) {
                        this.finished = true;
                    } else {
                        this.ServerList.push(...data.data.list);
                        if (this.ServerList.length == data.data.total || data.data.list === null) {
                            this.finished = true;
                        }
                        this.page.current_page += 1
                    }
                }
            }
            // 加载状态结束
            this.loading = false;
        },
        tabchange(name, title) {
            this.currentstatus = name
            this.ServerList = []
            this.page.current_page = 1
            this.finished = false
            this.loading = true
            this.onLoad()
        },
        //时间戳
        changeDate(val) {
            if (val != '') {
                let that = this;
                let date = new Date(val);
                let time1 = date.getTime() / 1000;
                let poor = time1 - 604800;
                let now = Date.parse(new Date()) / 1000;
                let judge = poor - now
                if (judge <= 0) {
                    return false
                } else {
                    return true
                }
            } else {
                let that = this;
                val = Date.parse(new Date()) / 1000
                let now = Date.parse(new Date()) / 1000;
                let judge = val - now
                if (judge <= 0) {
                    return false
                } else {
                    return true
                }
            }

        }
    }
};
</script>
