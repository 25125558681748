<template>
	<div class="application_box1">
		<div class="application">
			<div class="text">可提现金额</div>
			<div class="price">￥{{ tableForm.balance }}</div>
			<van-cell-group>
				<van-field class="num_input" v-model="nums" placeholder="请输入提现金额" />
			</van-cell-group>
			<div class="go_all" @click="handleAll(nums)">全部提现</div>
			<div class="note">请选择收款信息</div>
			<div class="select">
				<van-dropdown-menu>
					<van-dropdown-item v-model="value" :options="option" />
				</van-dropdown-menu>
			</div>
		</div>
		<div class="application_bottom">
			<div class="prompt">提示：1.当日提现次日到账，周六、周日及节假日顺延。</div>
			<div class="prompt_text">2.如需提现请完善实名认证</div>
			<div class="prompt_last">3.如需提现请完善安全信息</div>
			<van-button class="btn" :disabled="applyDisabled" color="#124DA5" @click="handleApply(nums)"
				v-if="info.auditStatus == 10 && info.phone != ''">申请提现</van-button>
			<van-button class="btn" color="#124DA5" @click="handleAudit()"
				v-if="info.auditStatus != 10">去实名认证</van-button>
			<van-button class="btn" color="#124DA5" @click="handleSafe()" v-if="info.phone == ''">去完成安全信息</van-button>
		</div>
	</div>
</template>

<script>
import {
	Distribution, Personal
} from "@/api/index";
export default {
	data() {
		return {
			applyDisabled: false,
			info: {},
			value: 0,
			nums: '',
			tableForm: {},
			option: [{
				text: '银行汇款',
				value: 0
			},
			{
				text: '微信收款码',
				value: 1
			},
			{
				text: '支付宝收款码',
				value: 2
			},
			]
		}
	},
	created() {
		this.Details();
		this.Info();
	},
	methods: {
		//个人信息
		async Info() {
			let { status, data } = await Personal.info();
			if (status) {
				if (data.code == 0) {
					this.info = data.data;
				}
			}
		},
		//去绑定安全信息
		handleSafe() {
			this.$router.push('/Info/wallet')
		},
		//去实名认证
		handleAudit() {
			this.$router.push('/Info/certification')
		},
		async Details() {
			let {
				status,
				data
			} = await Distribution.wallet();
			if (status) {
				if (data.code == 0) {
					this.tableForm = data.data;
				}
			}
		},
		handleAll(nums) {
			this.nums = this.tableForm.balance;
		},
		async handleApply(nums) {
			this.applyDisabled = true
			let {
				status,
				data
			} = await Distribution.withdrawal({
				balance: nums,
				payMode: this.value,
			});
			if (status) {
				if (data.code == 0) {
					this.applyDisabled = false
					this.$message.success("操作成功");
					this.Details();
					this.$router.replace('/Distribution/list')
				} else {
					this.$message.error(data.message);
				}
			}
		},
	}
};
</script>

<style lang="less">
.application_box1 {
	.application {
		margin-top: 10vw;
		padding-bottom: 6vw;
		border-bottom: 0.2vw solid #eee;

		.num_input {
			border: 0.2vw solid #124DA5;
			width: 50%;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			border-radius: 4vw;
		}

		.text {
			text-align: center;
			font-size: 3vw;
			color: #333;
			font-weight: bold;
		}

		.price {
			text-align: center;
			font-size: 3vw;
			color: #333;
			padding-top: 3vw;
			padding-bottom: 5vw;
		}

		.go_all {
			text-align: center;
			margin-top: 16vw;
			font-size: 3.5vw;
			color: #124DA5;
		}

		.note {
			font-size: 3.5vw;
			color: #999999;
			padding: 5vw;
		}

		.select {
			padding: 2vw 5vw;

		}
	}

	[class*=van-hairline]::after {
		width: 0;
	}

	.application_bottom {
		padding: 5vw;

		.prompt {
			font-size: 3.5vw;
			color: #999999;
			margin: 2vw 0 1vw 0;
		}

		.prompt_text {
			font-size: 3.5vw;
			color: #999999;
			text-indent: 10vw;
		}

		.prompt_last {
			font-size: 3.5vw;
			color: #999999;
			text-indent: 10vw;
			margin: 1vw 0 2vw 0;
		}

		.btn {
			border-radius: 1vw;
			width: 90vw;
		}
	}

}
</style>
