<template>
	<div class="forget">
		<div class="text">忘记密码</div>
		<van-form>
		  <van-field
		    v-model="submitForm.mobile"
		    type="text"
		    name="mobile"
		    label="手机号码"
		    placeholder="请输入手机号码"
			@blur="blur()"
		  />
		  <div class="warn"><van-icon name="info-o" /><div class="font">请输入绑定钱包时输入的手机号码</div></div>
		  <div class="input_text" v-if="showpassport">请选择要找回的账号</div>
		  <div class="choose">
		  	<div :class="{activity_item:item_index == index,item:item_index != index}"
		  		 v-for="(item,index) in PassportList"
		  		 @click="select(item,index)"
		  	>
		  		<div class="product_name">
		  			<div>{{item.passport}}</div>
		  		</div>
		  	</div>
		  </div>
		  <div class="input_text">图形验证码</div>
		  <div class="code">
		  	<van-field v-model="verifyCode" name="verifyCode" placeholder="请输入图形验证码" />
		  	<img style="width: 120px; height: 40px;" :src="verifyCodeImg" @click="handleRefresh()">
		  	<van-field v-model="verifyKey" name="verifyKey" style="display: none;" />
		  </div>
		  <div class="message">
			  <van-field
			    v-model="submitForm.code"
			    type="text"
			    name="code"
			    label="验证码"
			    placeholder="请输入验证码"
			  />
			  <van-button class="codebtn" v-if="showbtn" color="#1989fa"  @click="handleSend()">发送验证码</van-button>
			  <van-button class="codebtn codebtn_second" v-else color="#1989fa" :disabled="attcode">{{code_ts}}</van-button>
		  </div>
		  <div class="input_text">新密码</div>
		  <div class="code">
		  	<van-field v-model="password1"  placeholder="请输入新密码" />
		  </div>
		  <div class="input_text">请再次输入新密码</div>
		  <div class="code">
		  	<van-field v-model="password2"  placeholder="请再次输入新密码" />
		  </div>
		  <div style="margin: 16px;">
		    <van-button round block color="#124DA5" @click="handleSafe()">确定修改</van-button>
		  </div>
		</van-form>
	</div>
</template>

<script>
import {
		Admin
	} from "@/api/index";
export default {
  data() {
    return {
		showpassport:false,
		PassportList:[],
		item_index: 0,
		attcode: false, //点击获取验证码按钮判断
		showbtn: true, // 展示获取验证码或倒计时按钮判断
		code_ts: '获取验证码', //倒计时提示文字
		sec: 60, // 倒计时秒数
		verifyCode:'',
		verifyKey:'',
		verifyCodeImg:'',
		password1:'',
		password2:'',
		submitForm: {
      	mobile: '',
      	code: '',
      },
    };
  },
  created() {
	  this.handleCaptcha();
  },
  methods: {
	 select(item,index){
	 	if (this.item_index != index){
	 		this.item_index = index
	 	}
		this.Mobile = item.passport
	 },
	  //获取安全手机对应的passport
	async blur() {
		this.showpassport = true;
		let { status, data } = await Admin.getpassport({
			mobile:this.submitForm.mobile,
		});
		if (status) {
			if (data.code === 0) {
				this.PassportList = data.data.list
				let that = this;
				this.PassportList.forEach(function(item,index){
					if(index == 0) {
						that.Mobile = item.passport;
					}
				})
			} 
		} else {
			this.$message.error("data.message")
		}
	},
	//获取验证码
	async handleCaptcha(values) {
		let { status, data } = await Admin.verifyCode();
		if (status) {
			if (data.code === 0) {
				this.verifyCodeImg = data.data.img
				this.verifyKey = data.data.key
			} 
		} else {
			this.$message.error("data.message")
		}
	},
	//发送短信
	async handleSend(mobile) {
		let {
			status,
			data
		} = await Admin.sendsms({
			Mobile:this.submitForm.mobile,
			VerifyCode:this.verifyCode,
			VerifyKey:this.verifyKey,
		});
		if (status) {
			if (data.code == 0) {
				var timer = setInterval(() => {
					this.sec = this.sec - 1
					this.code_ts = this.sec + 'S后重试'
					this.showbtn = false
					this.attcode = true
					if (this.sec === 0) {
						clearInterval(timer)
						this.sec = 60
						this.code_ts = this.sec + 'S后重试'
						this.showbtn = true
						this.attcode = false
					}
				}, 1000)
				this.$message.success('发送成功');
			} else {
				this.$message.error(data.message);
			}
		}
	},
	//确定
	async handleSafe() {
		let {
			status,
			data
		} = await Admin.forget({
			Mobile:this.submitForm.mobile,
			passport:this.Mobile,
			password1:this.password1,
			password2:this.password2,
			Sms:this.submitForm.code
		});
		if (status) {
			if (data.code == 0) {
				this.$message.success('修改成功');
				this.$router.replace('/login');
			} else {
				this.$message.error(data.message);
			}
		}
	},
  },
};
</script>

<style lang="less">
	.forget {
		.choose {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 0px 20px;
			.activity_item {
				border: 0.2vw solid #eeeeee;
				border-radius: 3vw;
				padding: 3vw 8vw;
				margin-top: 2vw;
				background-color: #3988FF;
				color: #ffffff;
				.product_name {
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 3vw;
				}
			}
			
			.item {
				// width: 100%;
				border: 0.2vw solid #eeeeee;
				border-radius: 3vw;
				padding: 3vw 8vw;
				margin-top: 2vw;
				background-color: #ffffff;
				color: #999999;
				.product_name {
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 3vw;
				}
			}
		}
		.code {
			display: flex;
			align-items: center;
			padding-right: 16px;
		}
		.input_text {
			color: #646566;
			font-size: 3.5vw;
			margin-left: 5vw;
		}
		.text {
			font-size: 5vw;
			color: #333333;
			text-align: center;
			font-weight: bold;
			margin-top: 3vw;
		}
		.van-button {
			margin-top: 14vw;
			border-radius: 3vw;
		}
		.van-field__label {
			margin-bottom: 1vw;
		}
		.van-cell {
			display: block;
		}
		.van-field__control {
			border: 1px solid #E5E5E5;
			border-radius: 2vw;
			padding: 2vw;
		}
		.van-cell::after {
			display: none;
		}
		.warn {
			display: flex;
			align-items: center;
			font-size: 3vw;
			color: #999999;
			padding: 0 16px;
			margin-bottom: 3vw;
			.font {
				margin-left: 2vw;
			}
		}
		.van-form {
			margin: 10vw 5vw;
			.van-radio {
				overflow: visible;
			}
		}
		.van-radio {
			font-size: 3.5vw;
			margin-left: 3.5vw;
			margin-top: 5vw;
			color: #999999;
			.aggrement {
				color: #016FE1;
			}
		}
		.van-field__label {
			width: 7.2em;
		}
		.message {
			position: relative;
			.van-field__control {
				border: 1px solid #E5E5E5;
				border-radius: 2vw;
				padding: 2vw;
				width: 65%;
			}
			.van-button {
				margin: 0;
				position: absolute;
				right: 0;
				bottom: 10px;
				width: 35%;
			}
			.codebtn_second {
				opacity: 0.2;
			}
		}
	}
	
</style>
