<template>
	<div class="order_detail">
		<van-nav-bar title="业务详情" />
		<div class="buy_number">
			<div class="text">购买数量(PPS)</div>
			<div class="number">{{ Kuangform.ppsOrder }}</div>
		</div>
		<div class="buy_bottom">
			<div class="line">
				<span class="title">订单编号</span>
				<span class="content">{{ Kuangform.id }}</span>
			</div>
			<div class="line">
				<span class="title">pps数量</span>
				<span class="content">{{ Kuangform.ppsOrder }}</span>
			</div>
			<div class="line">
				<span class="title">价格</span>
				<span class="content">{{ Kuangform.ppsAmount }}</span>
			</div>
			<!-- <div class="line">
				<span class="title">托管时间(月)</span>
				<span class="content">({{ Kuangform.trusteeshipFeeMonth }}/{{ Kuangform.trusteeshipMonth }})</span>
			</div> -->
			<div class="line">
				<span class="title">购买时间</span>
				<span class="content">{{ Kuangform.createdAt }}</span>
			</div>
			<div class="line" v-if="Kuangform.status == 10">
				<span class="title">交付时间</span>
				<span class="content">{{ Kuangform.deliveryDays }}天</span>
			</div>
			<div class="line" v-if="Kuangform.status == 10">
				<span class="title">有效期</span>
				<span class="content">{{ Kuangform.ppsValidite }}</span>
			</div>
			<div class="line">
				<span class="title">订单状态</span>
				<span class="content">
					<span style="color: #84CE61;" v-if="Kuangform.status == 10">审核通过</span>
					<span style="color: #1989FA;" v-if="Kuangform.status == 1">待审核</span>
					<span style="color: #FF8C8D;" v-if="Kuangform.status == 0">未支付</span>
					<span style="color: #1989FA;" v-if="Kuangform.status == -1">用户取消</span>
					<span style="color: #FF8C8D;" v-if="Kuangform.status == -10">审核不通过</span>
				</span>
			</div>
			<div class="gopay">
				<van-button color="#1989FA" size="small" v-if="Kuangform.status == 0" @click="goPay(Kuangform.id)">去支付
				</van-button>
			</div>
			<div class="line" v-if="Kuangform.status == -10 || Kuangform.status == 10">
				<span class="title">审核时间</span>
				<span class="content">{{ Kuangform.auditAt }}</span>
			</div>
			<div class="line" v-if="Kuangform.status == -10">
				<span class="title">审核结果</span>
				<span class="content">{{ Kuangform.auditReason }}</span>
			</div>
		</div>
		<div class="trusteeship_list" v-if="false">
			<div class="tit">托管订单列表</div>
			<div class="trusteeship">
				<van-tabs v-model="active" @change="tabchange">
					<van-tab title="全部" style="margin-bottom: 15vw;" name="">
						<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="List()">
							<div class="server_list" v-for="(item, index) in ListData">
								<div class="item">
									<div class="left">
										<div class="text">
											<div class="name">托管订单编号:{{ item.id }}</div>
											<div class="parameter">
												{{ item.price }}(单价)X{{ item.month }}(数量)={{ item.amount }}(总价)</div>
											<div class="reason" v-if="item.status == -1">未通过原因:{{ item.auditReason }}
											</div>
										</div>
									</div>
									<div class="right">
										<div class="nums">{{ item.createdAt }}</div>
										<div class="status">
											<span style="color: #1989FA;" v-if="item.status == 1">审核通过</span>
											<span style="color: #FF8C8D;" v-if="item.status == -1">审核未通过</span>
											<span style="color: #84CE61;" v-if="item.status == 0">待审核</span>
										</div>

									</div>
								</div>
							</div>
						</van-list>
					</van-tab>
					<van-tab title="通过" style="margin-bottom: 15vw;" name="1">
						<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="List()">
							<div class="server_list" v-for="(item, index) in ListData">
								<div class="item">
									<div class="left">
										<div class="text">
											<div class="name">托管订单编号:{{ item.id }}</div>
											<div class="parameter">
												{{ item.price }}(单价)X{{ item.month }}(数量)={{ item.amount }}(总价)</div>
										</div>
									</div>
									<div class="right">
										<div class="nums">{{ item.createdAt }}</div>
										<div class="status">
											<span style="color: #1989FA;" v-if="item.status == 1">审核通过</span>
											<span style="color: #FF8C8D;" v-if="item.status == -1">审核未通过</span>
											<span style="color: #84CE61;" v-if="item.status == 0">待审核</span>
										</div>
									</div>
								</div>
							</div>
						</van-list>
					</van-tab>
					<van-tab title="未通过" style="margin-bottom: 15vw;" name="-1">
						<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="List()">
							<div class="server_list" v-for="(item, index) in ListData">
								<div class="item">
									<div class="left">
										<div class="text">
											<div class="name">托管订单编号:{{ item.id }}</div>
											<div class="parameter">
												{{ item.price }}(单价)X{{ item.month }}(数量)={{ item.amount }}(总价)</div>
											<div class="reason" v-if="item.status == -1">未通过原因:{{ item.auditReason }}
											</div>
										</div>
									</div>
									<div class="right">
										<div class="nums">{{ item.createdAt }}</div>
										<div class="status">
											<span style="color: #1989FA;" v-if="item.status == 1">审核通过</span>
											<span style="color: #FF8C8D;" v-if="item.status == -1">审核未通过</span>
											<span style="color: #84CE61;" v-if="item.status == 0">待审核</span>
										</div>

									</div>
								</div>
							</div>
						</van-list>
					</van-tab>
				</van-tabs>
			</div>
		</div>
	</div>
</template>

<script>
import {
	Server
} from "@/api/index";
export default {
	data() {
		return {
			active: '',
			id: '',
			Kuangform: {},
			ListData: [],
			//刷新
			loading: false,
			finished: false,
			page: {
				current_page: 1,
				pagesize: 10
			},
			currentstatus: ''
		};
	},
	created() {
		let {
			id
		} = this.$route.query;
		if (this.id == '') {
			this.id = id
		}
		this.KuangInfo()
	},
	methods: {
		//详情
		async KuangInfo() {
			let {
				status,
				data
			} = await Server.kuangInfo({
				id: this.id
			});
			if (status) {
				if (data.code == 0) {
					this.Kuangform = data.data.Detail;
				}
			}
		},
		//去支付
		goPay(id) {
			this.$router.push({
				path: "/Buy/second",
				query: {
					id: id
				}
			})
		},
		//托管列表
		async List() {
			if (!this.finished) {
				let {
					status,
					data
				} = await Server.trusteeshiplist({
					orderId: this.id,
					PageNum: this.page.current_page,
					status: this.currentstatus,
				});
				if (status) {
					if (data.code == 0) {
						if (data.data.list == null) {
							this.finished = true;
						} else {
							this.ListData.push(...data.data.list);
							this.finished = true;
							if (this.ListData.length == data.data.total || data.data.list === null) {
								this.finished = true;
							}
							this.page.current_page += 1
						}
					}
				}
			}
		},
		tabchange(name, title) {
			this.currentstatus = name
			this.ListData = []
			this.page.current_page = 1
			this.finished = false
			this.loading = true
			this.List()
		}
	},
};
</script>

<style lang="less">
.order_detail {
	.van-tabs__line {
		background-color: #124DA5;
	}

	.van-tabs__wrap {
		margin-bottom: 2vw;
	}

	.van-nav-bar__content {
		background-color: #124DA5;

		.van-nav-bar__title {
			color: #eee;
		}
	}

	.buy_number {
		padding: 5vw 5vw;
		border-bottom: 0.2vw solid #eee;

		.text {
			font-size: 3vw;
			color: #333333;
		}

		.number {
			font-size: 6vw;
			font-weight: bold;
			color: #333333;
			padding-top: 2vw;
		}
	}

	.buy_bottom {
		margin: 10vw 5vw 0;

		.gopay {
			text-align: right;
		}

		.line {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 3vw 0;

			.title {
				font-size: 4vw;
				color: #999999;
			}

			.content {
				font-size: 4vw;
				color: #333333;
			}
		}
	}

	.buy_btn {
		margin: 30vw 5vw 0;

		.van-button {
			border-radius: 2vw;
		}

		.van-button:first-child {
			margin-right: 2vw;
			width: 33vw;
		}

		.van-button:last-child {
			width: 55vw;
		}
	}

	.trusteeship_list {
		margin: 5vw 0;
		min-height: 75vh;

		.tit {
			padding: 2vw 0;
			text-align: center;
			border-top: 0.2vw solid #eee;
		}

		.trusteeship {
			.server_list {
				padding: 0 3vw;

				.item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 3vw 0;
					border-bottom: 0.2vw solid #eee;

					.go_btn {
						margin-top: 2vw;
						border-radius: 1.5vw;
						padding: 5px 5px;
					}

					.left {
						display: flex;
						align-items: center;

						.text {
							margin-left: 2vw;

							.name {
								display: flex;
								align-items: center;
								font-size: 4vw;
								font-weight: bold;
								color: #333333;
								padding-bottom: 1vw;

								.date {
									font-size: 3vw;
									font-weight: 100;
									color: #999999;
								}
							}

							.parameter {
								font-size: 4vw;
								color: #999999;
								padding-top: 1vw;
							}

							.reason {
								font-size: 3.5vw;
								color: #FF8C8D;
								padding-top: 1vw;
							}
						}
					}

					.right {
						text-align: right;
						font-size: 3.5vw;

						.nums {
							font-weight: 4vw;
							color: #333333;
							padding-bottom: 1vw;
						}

						.status {
							padding-top: 1vw;
						}
					}
				}
			}
		}
	}
}
</style>
