<template>
	<div class="distribution">
		<div class="page">
			<div class="distribution_bg">
				<div class="content">
					<div class="left">
						<p class="role">{{username}}
							<span v-if="info.agent == 1">-县级代理</span>
							<span v-if="info.agent == 2">-市级代理</span>
							<span v-if="info.agent == 3">-省级代理</span>
						</p>
						<p class="recommend">推荐码{{info.passport}}</p>
					</div>
					<div class="right">
						<img width="50px" src="../../assets/img/ic_logo.png" alt="智岚博丰">
					</div>
				</div>
				<div class="about_us">
					<div class="balance">
						￥{{walletData.balance}}
					</div>
					<div class="bottom">
						<div class="text">可提现金额(元)</div>
						<div class="gocash" @click="goCash">
							<span>去提现</span>
							<van-icon name="arrow" />
						</div>
					</div>
				</div>
			</div>
			<div class="distribution_middle">
				<div>
					<div class="left" @click="PPSteam()">
						<div class="number">{{info.teamCount}}</div>
						<div class="gocontribution">
							<span>直接销售</span>
						</div>
					</div>
					<div class="left leftBottom" v-if="this.$store.state.base.userId == 33">
						<div class="number">{{info.teamPps}}</div>
						<div class="gocontribution">
							<span>团队PPS</span>
						</div>
					</div>
				</div>
				<div class="right" @click="Team()">
						<img width="20px" src="../../assets/img/ic_lanfuwuqi.png" alt="智岚博丰">
						<p class="number">{{info.team}}</p>
						<p class="text">团队</p>
				</div>
			</div>
			<div class="distribution_bottom">
				<van-tabs v-model="active" @change="tabchange">
					<van-tab title="奖励明细" style="margin-bottom: 15vw;" name="0">
						<van-list v-model="loading"  :finished="finished"  finished-text="没有更多了"  @load="onLoadJ()">
							<div class="distribution_list" v-for="(item,index) in dealData">
								<div class="item" @click="goDetail(item)">
									<div class="top">
										<div class="name">
											<span >金额:{{item.reward}}</span>
										</div>
										<div class="number">订单号:{{item.orderId}}</div>
										<!-- <div class="number" v-if="item.rewardType == 10">直推10%</div>
										<div class="number" v-if="item.rewardType == 5">间推5%</div> -->
									</div>
									<div class="bottom">
										<div class="note">{{item.createdAt}}</div>
										<div class="go_detail">
											<div>查看详情</div>
											<van-icon name="arrow" />
										</div>
									</div>
								</div>
							</div>
						</van-list>
					</van-tab>
					<van-tab title="提现明细" style="margin-bottom: 15vw;" name="1">
						<van-list v-model="loading"  :finished="finished"  finished-text="没有更多了"  @load="onLoadT()">
							<div class="distribution_list" v-for="(item,index) in recordData">
								<div class="item" >
									<div class="top">
										<div class="name" style="color: #1989FA;">{{item.balance}}</div>
										<div class="number" style="color: #1989FA;" v-if="item.status == 0">申请中</div>
										<div class="number" style="color: #84CE61;" v-if="item.status == 1">审核通过</div>
										<div class="number" style="color: #FF8C8D;" v-if="item.status == -1">审核未通过</div>
									</div>
									<div class="bottom">
										<div class="note">提现时间:{{item.createdAt}}</div>
									</div>
									<div class="bottom">
										<div class="note">审核时间:{{item.checkedAt}}</div>
									</div>
									<div class="bottom">
										<div class="note" v-if="item.reason != ''">原因:{{item.reason}}</div>
									</div>
								</div>
							</div>
						</van-list>
					</van-tab>
				</van-tabs>
			</div>
		</div>
	</div>
</template>

<script>
import { Distribution,Home,Personal } from "@/api/index";
export default {
    data() {
      return {
		info:{},
		walletData:{},
		username:'',
		active:'',
		centerDialogVisible: false,
		form:{
			nums:'',
		},
		tableForm:[],
		dealData:[],
		recordData:[],
		//刷新
		loading: false,
		finished: false,
		page: {
		  current_page: 1,
		  pagesize: 10
		 },
		 
		currentstatus:0
      };
    },
	created() {
		this.Info();
		this.walletInfo();
	},
	methods:{
		//查看团队
		Team() {
			this.$router.push('/Distribution/team')
		},
		PPSteam() {
			this.$router.push('/Distribution/ppsteam')
		},
		//个人信息
		async Info() {
			let { status, data } = await Personal.info();
			if (status) {
				if(data.code == 0) {
					this.info = data.data;
					if(this.info.truename==''|| typeof(this.info.truename) == 'undefined') {
						this.username = this.info.passport
					}else {
						this.username = this.info.truename
					}
				}
			}
		},
		//钱包信息
		async walletInfo() {
			let { status, data } = await Distribution.wallet();
			if (status) {
				if(data.code == 0) {
					this.walletData = data.data;
				}
			}
		},
		goCash() {
			this.$router.push('/Distribution/application')
		},
		goDetail(item) {
			this.$router.push({
				path: '/Distribution/incomedetail',
				query: {
					id: item.id,
					orderId: item.orderId
				}
			})
		},
		//收入
		async onLoadJ() {
			if(!this.finished){
				let { status, data } = await Distribution.incomelist({PageNum: this.page.current_page});
				if (status) {
					if (data.data.list === null) {
						this.finished = true;
					} else {
						this.dealData.push(...data.data.list);
						if (this.dealData.length == data.data.total || data.data.list === null) {
							this.finished = true;
						}
						this.page.current_page += 1
					}
				}
			} 
			// 加载状态结束
			this.loading = false;
		},
		//提现列表
		async onLoadT() {
			if(!this.finished){
				let { status, data } = await Distribution.withdrawallist({PageNum: this.page.current_page});
				if (status) {
					if (data.data.list === null) {
						this.finished = true;
					} else {
						this.recordData.push(...data.data.list);
						if (this.recordData.length == data.data.total || data.data.list === null) {
							this.finished = true;
						}
						this.page.current_page += 1
					}
				}
			} 
			// 加载状态结束
			this.loading = false;
		},
		tabchange(name){
			this.recordData = []
			this.dealData = []
			this.page.current_page = 1
			this.finished = false
			this.loading = true
			switch(name){
				case "0":
				this.onLoadJ()
				break
				case "1":
				this.onLoadT()
				break
			}
		},
		// async handleApply (nums) {
		// 	let { status, data} = await Distribution.apply(this.form);
		// 	if(status) {
		// 		if(data.code == 200){
		// 			this.$message.success("操作成功");
		// 			this.Details();
		// 			this.onLoadJ();
		// 			this.onLoadT();
		// 		}else{
		// 			this.$message.error(data.message);	
		// 		}
		// 	}
		// },
		// goDeal(item){
		// 	this.$router.push({path:'tradingdetail?id='+item.id,params:{id:item.id}})
		// },
		// goCashR(item){
		// 	this.$router.push({path:'cashdetail?id='+item.id,params:{id:item.id}})
		// },
	},
  };
</script>

<style lang="less">
	.distribution {
		.van-tabs__line {
			background-color: #124DA5;
		}
		.van-tabs__wrap {
		    margin-bottom: 2vw;
		}
		.page {
			.distribution_bg {
				background: url(../../assets/img/ic_beijing4.png) no-repeat center left;
				background-color: #124DA5;
				width: 100vw;
				height: 66vw;
				border-bottom-right-radius: 9vw;
				.content {
					padding: 10vw 5vw;
					display: flex;
					justify-content: space-between;
					.left {
						.role {
							color: #fff;
							font-size: 3.5vw;
							padding-bottom: 1vw;
						}
						.recommend {
							color: #fff;
							font-size: 3.5vw;
							padding-top: 1vw;
						}
					}
				}
				.about_us {
					margin: 0vw 5vw;
					.balance {
						font-size: 6vw;
						color: #fff;
					}
					.bottom {
						padding-top: 10vw;
						display: flex;
						justify-content: space-between;
						.text {
							font-size: 3.5vw;
							color: #fff;
						}
						.gocash {
							span {
								font-size: 3.5vw;
								color: #fff;
								vertical-align: middle;
							}
							.van-icon {
								font-size: 3.5vw;
								color: #fff;
								vertical-align: middle;
							}
						}
					}
				}
			}
			.distribution_middle {
				margin: 5vw 5vw;
				display: flex;
				.left {
					width: 42.5vw;
					height: 24vw;
					background-image: linear-gradient(to right, #0860B0 , #134BA2);
					border-radius: 3vw;
					margin-right: 2.5vw;
					padding: 5vw;
					box-sizing: border-box;
					.number {
						font-size: 6vw;
						font-weight: bold;
						color: #fff;
					}
					.gocontribution {
						font-size: 3vw;
						color: #fff;
						padding-top: 1vw;
						span {
							vertical-align: middle;
						}
						.van-icon {
							vertical-align: middle;
						}
					}
				}
				.leftBottom {
					margin-top: 2vw;
				}
				.right {
					padding: 5vw;
					box-sizing: border-box;
					width: 42.5vw;
					height: 50vw;
					margin-left: 2.5vw;
					border-radius: 3vw;
					box-shadow: 0 0 10px rgba(0,0,0,0.1);
					.number {
						color: #124DA5;
						font-size: 4.5vw;
						font-weight: bold;
						padding-top: 6vw;
					}
					.gocontribution {
						font-size: 3vw;
						color: #fff;
						padding-top: 7vw;
					}
					img {
						padding-bottom: 2vw;
						
					}
					.text {
						font-size: 3.5vw;
						color: #999999;
						padding-top: 7vw;
					}
				}
			}
			.distribution_bottom {
				margin: 5vw 5vw;
			}
			.distribution_list {
				.item {
					padding: 5vw 0;
					border-bottom: 0.2vw solid #eee;
					.top {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-bottom: 1vw;
						.name {
							font-size: 3.5vw;
							color: #333333;
						}
						.number {
							font-size: 3.5vw;
						}
					}
					.bottom {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-top: 1vw;
						.note {
							font-size: 3.5vw;
							color: #999999;
						}
						.go_detail {
							font-size: 3.5vw;
							color: #999999;
							display: flex;
							align-items: center;
						}
						.van-icon {
							font-size: 3.5vw;
							color: #999999;
						}
					}
				}
			}
		}
	}
</style>
