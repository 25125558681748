import axios from 'axios';
//用户收入列表
let incomelist = (data = {}) => axios.get('/api/v1/operate/income', { params:data });
//用户提现列表
let withdrawallist = (data = {}) => axios.get('/api/v1/my/withdrawal', { params:data });
//用户钱包信息
let wallet = (data = {}) => axios.get('/api/v1/my/wallet', { params:data });
//申请提现
let withdrawal = (data = {}) => axios.post('/api/v1/my/withdrawal',data);
//收益详情
let incomedetail = (data = {}) => axios.get('/api/v1/operate/income/detail?id='+data.id, {params:{}});
//订单收益详情
let orderdetail = (data = {}) => axios.get('/api/v1/operate/income/all?orderId='+data.orderId, {params:{}});
//团队详情
let team = (data = {}) => axios.get('/api/v1/operate/team', { params:data });
//pps团队详情
let ppsteam = (data = {}) => axios.get('/api/v1/order/teamList', { params:data });
export default {
	incomelist,
	withdrawallist,
	wallet,
	withdrawal,
	incomedetail,
	orderdetail,
	team,
	ppsteam
}