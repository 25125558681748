<template>
	<div class="register">
		<van-form @submit="onSubmit">
		  <van-field
		    v-model="Passport"
		    name="Passport"
		    label="手机号码"
		    placeholder="请输入手机号码"
		  />
		  <van-field
		    v-model="Password"
		    type="password"
		    name="Password"
		    label="密码"
		    placeholder="请输入密码"
		  />
		  <van-field
		    v-model="Password2"
		    type="password"
		    name="Password2"
		    label="重复密码"
		    placeholder="请再次输入密码"
		  />
		  <van-field
		    v-model="Recommended"
		    name="Recommended"
		    label="推荐码"
		    placeholder="请输入推荐码"
		  />
		  <div class="code">
			  <van-field
			    v-model="verifyCode"
			    name="verifyCode"
			    label="验证码"
			    placeholder="请输入验证码"
			  />
			  <img style="width: 120px; height: 40px;" :src="verifyCodeImg" @click="handleRefresh()">
		  </div>
		  
		  <van-field
		    v-model="verifyKey"
		    name="verifyKey"
		  	style="display: none;"
		  />
		  <!-- <van-radio-group v-model="radio" direction="horizontal">
		    <van-radio name="1">同意会员<a href="###" class="aggrement">注册协议</a></van-radio>
		  </van-radio-group> -->
		  <div style="margin: 16px;">
		    <van-button round block color="#124DA5" native-type="submit">注册</van-button>
		  </div>
		</van-form>
		<div class="bottom">已有账号?请点击<a @click="goLogin()" class="goregister">去登录</a></div>
	</div>
</template>

<script>
import {Admin} from "@/api/index";
export default {
  data() {
    return {
	  Passport:'',
      Password: '',
	  Password2:'',
	  Recommended:'',
	  // radio:'',
	  verifyCode:'',
	  verifyKey:'',
	  verifyCodeImg:''
    };
  },
  created() {
  	this.handleCaptcha()
  },
  methods: {
	  goLogin() {
		  this.$router.replace('/login')
	  },
	  //获取验证码
	  async handleCaptcha(values) {
	  	let { status, data } = await Admin.verifyCode();
	  	if (status) {
	  		if (data.code === 0) {
	  			this.verifyCodeImg = data.data.img
	  			this.verifyKey = data.data.key
	  		} 
	  	} else {
	  		this.$message.error("data.message")
	  	}
	  },
	  //点击图片重新获取验证码
	  handleRefresh() {
	  	this.handleCaptcha();
	  },
	  async onSubmit(values) {
		  // if(this.radio == ''){
			 //  this.$message.error("请先勾选注册协议");
			 //  return
		  // }
		  if(this.Password.length < 6){
		  		this.$message.error("密码至少为六位");
		  		return
		  }
		let { status, data } = await Admin.register(values);
		if (status) {
			if(data.code != 0){
				this.$message.error(data.message);
				return
			}else {
				this.$message.success('注册成功');
				this.$router.replace('/login');
			}
			
		} else {
			this.$message.error(data.data);
		}
    },
  },
};
</script>

<style lang="less">
	.register {
		.van-button {
			margin-top: 14vw;
			border-radius: 3vw;
		}
		.van-cell {
			
		}
		.van-form {
			margin: 10vw 5vw;
			.van-radio {
				overflow: visible;
			}
		}
		.code {
			display: flex;
			align-items: center;
			padding-right: 16px;
		}
		.van-radio {
			font-size: 3.5vw;
			margin-left: 3.5vw;
			margin-top: 5vw;
			color: #999999;
			.aggrement {
				color: #016FE1;
			}
		}
		.van-field__label {
			width: 5em;
		}
		.bottom {
			text-align: center;
			font-size: 3.5vw;
			color: #999999;
			.goregister {
				color: #016FE1;
			}
		}
	}
	
</style>
