<template>
	<div class="team">
		<van-nav-bar title="团队详情" />
		<div class="team_box">
			<div class="allot">
				<div class="line">
					<span class="title username">用户编号</span>
					<span class="title date">姓名</span>
					<span class="title num">账号</span>
				</div>
				<van-list v-model="loading"  :immediate-check="false" :finished="finished" finished-text="没有更多了" @load="teamDetail()">
					<div class="team_list" v-for="(item,index) in teamData">
						<div class="item">
							<div class="title">{{item.id}}</div>
							<div class="text">
								<span v-if="item.profile.truename != ''">{{item.profile.truename}}</span>
							</div>
							<div class="numtext">
								<span>{{item.passport}}</span>
							</div>
						</div>
					</div>
				</van-list>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Distribution
	} from "@/api/index";
	export default {
		data() {
			return {
				teamData: [],
				//刷新
				loading: false,
				finished: false,
				page: {
					current_page: 1,
					pagesize: 10
				},
			}
		},
		created() {
			this.teamDetail()
		},
		methods: {
			async teamDetail() {
				// if(this.$store.state.base.userId == 10) {
					if (!this.finished) {
						let {
							status,
							data
						} = await Distribution.team();
						if (status) {
							if (data.code == 0) {
								if (data.data.list == null) {
									this.finished = true;
								} else {
									this.teamData.push(...data.data.list);
									this.finished = true;
									if (this.teamData.length == data.data.total || data.data.list === null) {
										this.finished = true;
									}
									this.page.current_page += 1
								}
							}
						}
					}
				// }
			},
		}
	}
</script>

<style lang="less">
	.team {
		min-height: 75vh;

		.van-nav-bar__content {
			background-color: #124DA5;

			.van-nav-bar__title {
				color: #eee;
			}
		}

		.tit {
			padding: 2vw 0;
			text-align: center;
			border-bottom: 0.2vw solid #eee;
		}

		.team_box {
			padding: 5vw;

			.line {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 3vw 0;

				.title {
					font-size: 4vw;
					color: #999999;
					text-align: center;
				}
				.username {
					width: 20%;
					text-align: center;
				}
				.date {
					width: 40%;
					text-align: center;
				}
				.num {
					width: 40%;
					text-align: center;
				}
			}

			.team_list {
				.item {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 3vw 0vw;
					border-bottom: 0.2vw solid #eee;
					.title {
						width: 20%;
						font-size: 4vw;
						color: #999999;
						text-align: center;
					}
					.text {
						width: 40%;
						font-size: 4vw;
						color: #333333;
						text-align: center;
					}
					.numtext {
						width: 40%;
						font-size: 4vw;
						color: #333333;
						text-align: center;
					}
				}
			}
		}
	}
</style>
