<template>
  <div id="app">
	  <div v-title data-title="aleopool"></div>
    <router-view ></router-view>
  </div>
</template>

<script>
export default {
	methods: {
		 isMobile() {
		 	 let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
		 	 return flag;
		 },
	},
	mounted() {
		 var clientWidth = document.documentElement.clientWidth
		if (!this.isMobile() && clientWidth > 1237) {
			 // window.location.href='http://pc.365supports.com/';
		}
	  }
}
</script>

<style lang="less">
//公共类
@import "./assets/css/reset.css";

</style>
