import Vue from "vue";
import VueRouter from "vue-router";

import { setCookie, getCookie } from "@/assets/js/cookie.js";
Vue.use(VueRouter);
import Login from "../views/Admin/login.vue";
import Register from "../views/Admin/register.vue";
import Lose from "../views/Admin/404.vue";
import Forget from "../views/Admin/forget.vue";
import Home from "../components/Layout.vue";
import Homeindex from "../views/Home/index.vue";
import Server from "../components/Layout.vue";
import Info from "../components/Layout.vue";
import Assets from "../components/Layout.vue";
import Asset from "../views/Assets/assets.vue";
import IoAsset from "../views/Assets/ioassets.vue";
import Assetslist from "../views/Assets/list.vue";
import ServerList from "../views/Server/list.vue";
import ServerDetail from "../views/Server/detail.vue";
import ServerTrusteeship from "../views/Server/trusteeship.vue";
import Personal from "../views/Info/personal.vue";
import InfoPower from "../views/Info/power.vue";
import PersonalCertification from "../views/Info/certification.vue";
import PersonalWallet from "../views/Info/wallet.vue";
import PersonalPassword from "../views/Info/password.vue";
import Buy from "../components/Layout.vue";
import Second from "../views/Buy/second.vue";
import Power from "../views/Buy/power.vue";
import BuyFirst from "../views/Buy/first.vue";
import Distribution from "../components/Layout.vue";
import DistributionList from "../views/Distribution/list.vue";
import Team from "../views/Distribution/team.vue";
import PPSTeam from "../views/Distribution/ppsteam.vue";
import DistributionIncomeDetail from "../views/Distribution/incomedetail.vue";
import DistributionApplication from "../views/Distribution/application.vue";
const routes = [
  {
    path: "/login",
    name: "Login",
    alias: "/",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "*",
    name: "404",
    component: Lose,
  },
  {
    path: "/forget",
    name: "Forget",
    component: Forget,
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
    children: [
      {
        path: "index",
        name: "Index",
        component: Homeindex,
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
  {
    path: "/Assets",
    name: "Assets",
    component: Assets,
    children: [
      {
        path: "assets",
        name: "Asset",
        component: Asset,
        meta: {
          requireAuth: true,
        },
      },
	  {
        path: "ioassets",
        name: "IoAsset",
        component: IoAsset,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "list",
        name: "List",
        component: Assetslist,
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
  {
    path: "/Server",
    name: "Server",
    component: Server,
    children: [
      {
        path: "list",
        name: "List",
        component: ServerList,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "detail",
        name: "Detail",
        component: ServerDetail,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "trusteeship",
        name: "Trusteeship",
        component: ServerTrusteeship,
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
  {
    path: "/Buy",
    name: "Buy",
    component: Buy,
    children: [
      {
        path: "first",
        name: "First",
        component: BuyFirst,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "second",
        name: "Second",
        component: Second,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "power",
        name: "Power",
        component: Power,
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
  {
    path: "/Distribution",
    name: "Distribution",
    component: Distribution,
    children: [
      {
        path: "list",
        name: "List",
        component: DistributionList,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "application",
        name: "Application",
        component: DistributionApplication,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "incomedetail",
        name: "Incomedetail",
        component: DistributionIncomeDetail,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "team",
        name: "Team",
        component: Team,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "ppsteam",
        name: "PPSTeam",
        component: PPSTeam,
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
  {
    path: "/Info",
    name: "Info",
    component: Info,
    children: [
      {
        path: "personal",
        name: "Personal",
        component: Personal,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "certification",
        name: "Certification",
        component: PersonalCertification,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "wallet",
        name: "Wallet",
        component: PersonalWallet,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "password",
        name: "Password",
        component: PersonalPassword,
        meta: {
          requireAuth: true,
        },
      },{
        path: "power",
        name: "Power",
        component: InfoPower,
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
];
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
// if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
// return originalPush.call(this, location).catch(err => err)
// }
import store from "@/store/index";
const router = new VueRouter({
  routes,
  mode: "history",
});

let token = localStorage.getItem("token");
let cookie = getCookie("base");

// 全局路由守卫，如果不是登录状态，则到登录页面
router.beforeEach((to, from, next) => {
  //验证token存在，则进入该页面
  // if (token != '' && token != null && typeof(token) != undefined) {
  if (token != "null") {
    next();
  } else {
    //否则进入不需要验证token界面,（这里进入的是登录页面）
    if (cookie) {
      localStorage.token = JSON.parse(cookie).token;
      localStorage.vuex = cookie;
      next();
    } else {
      if (to.path === "/login") {
        next();
      } else if (to.path === "/register") {
        next();
      } else if (to.path === "/forget") {
        next();
      } else {
        if (token == "null") {
          next({ path: "/login" });
        }
        next({ path: "/login" });
      }
    }
  }
});
export default router;
