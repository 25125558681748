<template>
	<div class="assets_list">
		<div class="page_title">我的通证(IO)</div>
		<div class="asset_detail">
			<div class="filecoin">

			</div>
			<div class="asset-items">
				<div class="item">
					<p class="text">总奖励</p>
					<p class="number">{{ total }}</p>
				</div>
				<div class="item">
					<p class="text">可提现</p>
					<p class="number">{{ io }}</p>
				</div>
				<div class="item">
					<p class="text">冻结中</p>
					<p class="number">{{ freeze }}</p>
				</div>
			</div>
		</div>
		<!-- <div class="asset_total">
			<div class="text">总通证数</div>
			<div class="number">{{Formdata.data.total}}</div>
		</div> -->
		<div class="btn_operation">
			<!-- <van-button v-if="judge_realname"  type="info" @click="goAuthentication()">请实名认证</van-button>
			<van-button  v-if="judge_wallet" plain type="info" @click="onClickvalidation">钱包与手机验证</van-button> -->
		</div>
		<div class="btn_withdrawal">
			<!-- <van-button  v-if="judge_withdrawal" plain type="info" @click="onClickMultiple" style="display: none;!important" disabled>批量提现</van-button> -->
		</div>
		<div class="title">通证详情</div>
		<div class="pool" v-for="item in Formdata.data.list">
			<div class="pool_name">
				<span>日期：{{ item.createdAt }}</span>
			</div>
			<div class="terms">
				<div class="item">
					<span>通证数量：{{ item.amount }}</span>
					<p class="text">来源用户：{{ item.fromUserId }}</p>
				</div>
			</div>
		</div>
		<!-- <van-dialog v-model="show" title="申请提现" confirmButtonText='提现' show-cancel-button @confirm="handleMultiple(nums)">
			<div class="dialog">实际可提现总数</div>
			<div class="cash_num">{{Formdata.data.cash_int}}</div>
			<van-field v-model="nums" type="digit"  label="输入提现数量" placeholder="提现数量格式为正整数" />
		</van-dialog> -->
	</div>
</template>

<script>
import { Property, Personal } from "@/api/index";
export default {
	data() {
		return {
			show: false,
			Formdata: { data: {} },
			mineform: [],
			judge_withdrawal: false,
			judge_realname: false,
			judge_wallet: false,
			infoform: [],
			mineform: [],
			nums: '',
			page: {
				current_page: 1,
				pagesize: 10,
			},
			total: 0,
			io: 0,
			freeze: 0,
		};
	},
	created() {
		// this.Cash()
		// this.Pools()
		// this.Ifinfo()
		this.ioList()
		Personal.ioWallet().then((res) => {
			this.total = res.data.data.total;
			this.io = res.data.data.balance;
			this.freeze = res.data.data.freeze;
		})
	},
	methods: {
		async ioList() {
			let { status, data } = await Personal.ioList({
				PageNum: this.page.current_page,
			});
			if (status) {
				this.Formdata = data;
				// this.Ifinfo();
			}
		},
		// goAuthentication() {
		// 	this.$router.push('/Info/certification')
		// },
		// async Cash() {
		// 		let { status, data } = await Property.cash();
		// 		if (status) {
		// 			this.Formdata = data;
		// 			this.Ifinfo()
		// 		}
		// },
		// async Pools() {
		// 		let { status, data } = await Property.pools(); 
		// 		if (status) {
		// 			this.mineform = data.data;
		// 		}
		// },
		// goParam(item){
		// 		 this.$router.push({path:'list?pool_id='+item.pool_id,params:{pool_id:item.pool_id}})
		// },
		//判断信息
		// async Ifinfo() {
		// 	let { status, data } = await Personal.info();
		// 		if (status) {
		// 				this.infoform = data.data;

		// 				//提现判断
		// 				this.judge_withdrawal = this.infoform.status==5 && this.Formdata.data.cash > 0 && this.infoform.mobile_status == 1;
		// 				//实名判断
		// 				this.judge_realname = this.infoform.status != 5;
		// 				//钱包判断
		// 				this.judge_wallet = (this.infoform.wallet == '' ||this.infoform.wallet == null) && this.infoform.mobile_status == 0;
		// 		}	
		// 	},
		//提现按钮
		// onClickMultiple() {
		// 	this.show = true
		// },
		//批量提现
		// async handleMultiple(nums) {
		// 	let { status, data} = await Property.multiple({nums:nums},this.form);
		// 		if(status) {
		// 			if(data.code == 200){
		// 				this.$message.success(data.data);
		// 				this.Cash()
		// 				this.Pools()
		// 				this.Ifinfo()
		// 			}else{
		// 				this.$message.error(data.message);	
		// 			}
		// 		}
		// },
		//验证按钮
		// onClickvalidation() {
		// 	this.$router.push('/Info/binding')
		// },
	},

}
</script>
