import axios from 'axios';
//用户持币总数
let cash = (data = {}) => axios.get('/api/user_coin', { params:data });
//获取用户矿池列表
let pools = (data = {}) => axios.get('/api/user_coin/pools', { params:data});
//用户矿池详情
let poolsinfo = (data) => axios.get('/api/user_coin/pools/info', { params:{pool_id:data.pool_id}});
//分币分红明细
let sharebonus = (data) => axios.get('/api/user_coin/records', { params:data});
//提现记录
let withdrawalList = (data = {}) => axios.get('/api/v1/my/aleoWithdrawal', { params:data});
//单矿池提现
let single = (data = {}) => axios.post('/api/v1/my/aleoWithdrawal',{balance:data.nums});
//批量提现
let multiple = (data = {}) => axios.post('/api/user_coin/multiple_withdraw',{nums:data.nums});
//每日分币/分红明细
let poolday = (data) => axios.get('/api/user_coin/day', { params:data});
//自由分期列表
let freeinstall = (data) => axios.get('/api/user_coin/stage', { params:data});
//180天锁仓明细
let release180s = (data) => axios.get('/api/user_coin/release180s', { params:data});
export default {
	cash,
	pools,
	poolsinfo,
	sharebonus,
	withdrawalList,
	single,
	multiple,
	poolday,
	freeinstall,
	release180s
}
