<template>
    <div class="second_buy">
        <div class="second_title">
            <div class="CN">购买电力</div>
            <div class="EN">Power Buy</div>
            <img width="40px" src="../../assets/img/ic_gou.png" alt="POWER">
        </div>
        <div class="second_item">
            <div class="name">购买数量</div>
            <div class="erver_name">{{ powerTotal }}份</div>
        </div>
        <div class="second_item">
            <div class="name">购买时长</div>
            <div class="erver_name">3个月</div>
        </div>
        <div class="second_item">
            <div class="amount">金额总计</div>
            <div class="erver_name">{{ amount }}元</div>
        </div>
        <div class="second_item" style="display: block;">
            <div class="name">付款信息</div>
            <div class="info">
                <div class="card_code">
                    <div>卡号：{{ card_code }}</div>
                    <div class="recommend" v-clipboard:copy="card_code" v-clipboard:success="onCopy"
                        v-clipboard:error="onError"><img width="18px" src="../../assets/img/copy_code.png" alt=""></div>
                </div>
                <div class="card_code">姓名：刘学奇</div>
                <div class="card_code">开户行：潍坊银行</div>
            </div>
        </div>
        <div class="upload_item">
            <van-uploader v-model="fileList" multiple :after-read="upload" max-count="6" @delete="delimage">
                <div class="upload_click">
                    <img width="42.33px" src="../../assets/img/ic_tupian.png" alt="">
                    <div class="text">点击上传凭证</div>
                </div>
            </van-uploader>
        </div>
        <div class="note_item">
            <van-field v-model="ruleForm.remarks" rows="2" autosize type="textarea" maxlength="1000"
                placeholder="请填写备注(选填)" show-word-limit />
        </div>
        <div class="check">
            <!-- <van-checkbox v-model="agree" icon-size="16px">
				<span>阅读并同意以上条款</span>
			</van-checkbox> -->
        </div>
        <div class="second_btn">
            <van-button color="#FF5722" @click="onPay" :disabled="disabled">提交</van-button>
        </div>
    </div>
</template>

<script>
import {
    Buy
} from '@/api/index';
export default {
    data() {
        return {
            card_code: '6223913800182406',
            disabled: false,
            fileList: [],
            checked: false,
            buyDetail: {},
            ruleForm: {
                power: 0,
                amount: 0,
                // paymentUrl: '',
                remarks: '',
                powerType: 1,
                ids: '',
            },
            image_urls: [],
            // agree: '',
            uploadFileList: [],
            powerTotal: 0,
            amount: 0,
        };
    },
    created() {
        this.powerTotal = this.$route.query.total;
        this.amount = this.powerTotal * 3 * 6;
        this.ruleForm.power = this.powerTotal;
        this.ruleForm.amount = this.amount;
        this.ruleForm.powerType = this.$route.query.type;
        this.ruleForm.ids = this.$route.query.ids;
    },
    methods: {
        upload(file) {
            let images = []
            if (typeof file.length == 'undefined') {
                images = [file]
            } else {
                images = file
            }
            let that = this
            that.disabled = true;
            images.forEach(function (item) {
                file.status = 'uploading';
                file.message = '上传中...';
                let formData = new FormData();
                formData.append('file', item.file);
                Buy.ImageUpload(formData).then(res => {
                    if (res.data.data.path != '') {

                        file.status = 'success';
                        file.message = '上传成功';
                        that.disabled = false;
                        that.image_urls.push(res.data.data.path)
                        that.$message.success('上传成功')
                    } else {
                        file.status = 'failed';
                        file.message = '上传失败';
                        that.disabled = true;
                    }

                }).catch(err => { })
            })
        },
        delimage(file, index) {
            this.image_urls.filter((item, indexs) => {
                if (index.index == indexs) {
                    this.image_urls.splice(indexs, 1); //splice 删除
                }
            });
            return true;
        },
        async onPay() {
            // if (!this.agree) {
            // 	this.$message.error('请先勾选同意合同条款')
            // 	return
            // }
            if (this.image_urls.length == 0) {
                this.$message.error('请选择付款凭证上传')
                return
            }
            this.ruleForm.proof = this.image_urls
            let {
                status,
                data
            } = await Buy.power(this.ruleForm);
            if (status) {
                if (data.code == 0) {
                    this.$message.success('操作成功')
                    this.$router.replace('/Info/power')
                } else {
                    this.$message.error(data.message)
                }
            }
        },
        onCopy(e) { 　　 // 复制成功
            this.$message.success('复制成功')
        },
        onError(e) {　　 // 复制失败
            this.$message.success('复制失败')
        },
    },
    computed: {
        // totolPrice() {
        // 	// if(Object.keys(this.kuang).length == 0){
        // 	// 	return 0;
        // 	// }
        // 	return this.buyDetail.ppsBuyNumber * this.buyDetail.ppsPrice
        // },
    }
};
</script>
<style lang="less">
.amount {
    color: red;
}
</style>
