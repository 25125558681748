require("./plugins/axios");
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import message from "@/util/message";
import "@/plugins/axios";
import { Dialog } from "vant";
import VueClipboard from "vue-clipboard2";
// 引入路由模块
import VueRouter from "vue-router";
//push
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

// replace
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
// 挂载路由模块
Vue.use(VueRouter);

Vue.prototype.$message = message;
Vue.use(Vant);
Vue.use(Dialog);
Vue.use(VueClipboard);
Vue.config.productionTip = false;
Vue.config.flag = true;
//title
Vue.directive("title", {
  inserted: function(el, binding) {
    document.title = el.dataset.title;
  },
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
