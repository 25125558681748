<template>
  <div class="assets_list">
    <div class="page_title">我的通证(ALEO)</div>
    <div class="asset_detail">
      <div class="filecoin"></div>
      <div class="asset-items">
        <div class="item">
          <p class="text">总奖励</p>
          <p class="number">{{ total }}</p>
        </div>
        <div class="item">
          <p class="text">可提现</p>
          <p class="number">{{ aleo }}</p>
        </div>
        <div class="item">
          <p class="text">冻结中</p>
          <p class="number">{{ freeze }}</p>
        </div>
      </div>
    </div>
    <!-- <div class="asset_total">
			<div class="text">总通证数</div>
			<div class="number">{{Formdata.data.total}}</div>
		</div> -->
    <div class="btn_operation">
      <van-button v-if="judge_realname" type="info" @click="goAuthentication()">请实名认证</van-button>
      <van-button v-if="judge_wallet" plain type="info" @click="onClickvalidation">钱包与手机验证</van-button>
    </div>
    <div class="btn_operation">
      <van-button plain type="info" @click="onClickList">提现详情</van-button>
      <van-button type="info" @click="onClickMultiple" :disabled="!judge_withdrawal">提现</van-button>
    </div>
    <div class="btn_operation">注意：<br />
      1.每天中午12点之后可提现<br />
      2.提现前请完成钱包与手机验证<br />
      3.提现前请完成实名认证
    </div>
    <div class="title">通证详情</div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad()">
      <div class="server_list pool" v-for="(item, index) in dispatchList">
        <div class="pool_name">
          <span>日期：{{ shortDate(item.dispatchDate) }}</span>
        </div>
        <div class="terms">
          <div class="item">
            <span>通证数量：{{ item.amount }}</span>
            <span class="text">来源用户：{{ item.fromUserId }}</span>
          </div>
        </div>
      </div>
    </van-list>
    <van-dialog v-model="show" title="提现" confirmButtonText='提现' show-cancel-button @confirm="handleMultiple(nums)">
      <div class="balance">可提现余额:<span class="cash_num">{{ aleo }}</span></div>
      <van-field v-model="nums" type="digit" label="输入提现数量" placeholder="提现数量为大于等于1的正整数" />
      <van-field v-model="withFax" type="number" label="实际到账数量" placeholder="实际到账数量" readonly />
      <van-field v-model="address" type="textarea" rows="3" autosize label="提现到账地址" readonly />
      <div class="balance" style="font-size: 0.8rem;">提现提醒：</div>
      <div class="balance" style="font-size: 0.8rem;">1.最小提现数为1，不支持小数</div>
      <div class="balance" style="font-size: 0.8rem;">2.提现需要扣除手续费每笔0.154</div>
    </van-dialog>
  </div>
</template>

<script>
import { Property, Personal } from "@/api/index";
import { watch } from "less";
import { Dialog } from 'vant';
export default {
  data() {
    return {
      show: false,
      Formdata: { data: {} },
      mineform: [],
      judge_withdrawal: false,
      judge_realname: false,
      judge_wallet: false,
      infoform: [],
      mineform: [],
      loading: false,
      finished: false,
      dispatchList: [],
      nums: "",
      withFax: "",
      address: "",
      page: {
        current_page: 1,
        pagesize: 10,
      },
      total: 0,
      aleo: 0,
      freeze: 0,
    };
  },
  created() {
    // this.aleoList()
    this.syncWallet();
    this.Ifinfo();
  },
  watch: {
    nums(newVal, oldVal) {
      if (newVal > this.aleo) {
        this.$message.error('提现金额不能大于余额');
        this.nums = 0;
        return
      }
      if (newVal <= 0) {
        this.withFax = 0
      } else {
        this.withFax = newVal - 0.154
      }
    }
  },
  methods: {
    shortDate(dispatchDate) {
      return dispatchDate.substr(0, 10)
    },
    async syncWallet() {
      Personal.aleoWallet().then((res) => {
        this.total = res.data.data.total;
        this.aleo = res.data.data.balance;
        this.freeze = res.data.data.freeze;
      })
    },
    async onLoad() {
      if (!this.finished) {
        let {
          status,
          data
        } = await Personal.aleoList({
          PageNum: this.page.current_page
        });
        if (status) {
          if (data.data.list === null) {
            this.finished = true;
          } else {
            this.dispatchList.push(...data.data.list);
            if (this.dispatchList.length == data.data.total || data.data.list === null) {
              this.finished = true;
            }
            this.page.current_page += 1
          }
        }
      }
      // 加载状态结束
      this.loading = false;
    },
    // async aleoList() {
    //   let { status, data } = await Personal.aleoList({
    //     PageNum: this.page.current_page,
    //   });
    //   if (status) {
    //     this.Formdata = data;
    //     this.Ifinfo();
    //   }
    // },
    goAuthentication() {
      this.$router.push('/Info/certification')
    },
    //验证按钮
    onClickvalidation() {
      this.$router.push('/Info/wallet')
    },
    onClickList() {
      this.$router.push('/Assets/list')
    },
    onClickMultiple() {
      if (this.infoform.ppsTotal !== this.infoform.power) {
        Dialog.confirm({
          title: "注意",
          message: '提现之前请确认有效电力与已购PPS份数相同，如果已经购买相同份数电力，请等待电力订单审核通过后重试！\n\r电力订单查看：我的->电力订单',
          showCancelButton: true,
          cancelButtonText: '关闭',
          cancelButtonColor: 'red',
          confirmButtonText: '查看电力订单',
          confirmButtonColor: 'green',
        }).then(() => {
          this.$router.push('/Info/power')
        }).catch(() => { });
        return
      }
      this.show = true;
    },
    //提现
    async handleMultiple(nums) {
      if (nums > this.aleo) {
        this.$message.error('提现金额不能大于余额')
        return
      }
      if (nums == 0) {
        this.$message.error('请输入提现金额')
        return
      }
      let { status, data } = await Property.single({ nums: nums }, this.form);
      if (status) {
        if (data.code === 0) {
          this.$message.success("提现成功，等待审核！");
          this.nums = 0;
          this.withFax = 0;
          this.syncWallet()
        } else {
          this.$message.error(data.message);
        }
      }
    },
    //判断信息
    async Ifinfo() {
      let { status, data } = await Personal.info();
      if (status) {
        this.infoform = data.data;
        this.address = this.infoform.aleoAddress;
        //提现判断
        const now = new Date();
        const timeLimit = (now.getHours() >= 12);
        this.judge_withdrawal = this.infoform.auditStatus == 10 && this.infoform.phone !== "" && this.infoform.aleoAddress !== "" && timeLimit;
        //实名判断
        this.judge_realname = this.infoform.auditStatus != 10;
        //钱包判断
        this.judge_wallet = (this.infoform.aleoAddress == '' || this.infoform.aleoAddress == null);
      }
    },
  },
};
</script>

<style lang="less">
.assets_list {
  padding-bottom: 20vw;
  background-color: #f8f8fb;
  min-height: 100vh;

  .van-dialog__header {
    font-weight: bold;
  }

  .balance {
    padding: 8px 16px;
  }

  .page_title {
    font-size: 8vw;
    font-weight: bold;
    color: #333333;
    padding: 5vw 5vw 0vw 5vw;
  }

  .filecoin {
    margin: 10vw 5vw 2vw;

    .text {
      vertical-align: middle;
      color: #ffffff;
      font-size: 3.5vw;
      margin-right: 2vw;
    }

    img {
      vertical-align: middle;
    }
  }

  .asset_total {
    background-color: #ffffff;
    margin: 0 5vw;
    padding: 5vw 4vw;
    box-sizing: border-box;
    border-radius: 4vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      font-size: 4vw;
      font-weight: bold;
    }

    .number {
      font-size: 4vw;
      color: #3988ff;
    }
  }

  .asset_detail {
    background: url(../../assets/img/asset_background.png) no-repeat center center;
    background-size: contain;
    padding: 25vw 0;
    margin: 0 5vw;
    position: relative;

    .filecoin {
      position: absolute;
      top: 0vw;
      left: 0vw;
      color: #ffffff;
    }

    .asset-items {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80vw;

      .item {
        width: 49.5vw;
        text-align: center;

        .number {
          color: #ffffff;
          font-weight: bold;
          font-size: 4vw;
          padding-top: 4vw;
        }

        .text {
          color: #ffffff;
          font-size: 3vw;
        }
      }
    }
  }

  .btn_operation {
    // padding: 0 5vw;
    margin: 2vw 5vw;

    .van-button {
      width: 49%;
      border-radius: 3vw;
      margin-top: 1vw;
    }

    .van-button:first-child {
      margin-right: 2%;
    }
  }

  .btn_withdrawal {
    // padding: 0 5vw;
    margin: 2vw 5vw;

    .van-button {
      width: 100%;
      border-radius: 3vw;
      margin-top: 0;
    }
  }

  .title {
    margin: 5vw 5vw;
    color: #124da5;
    font-weight: bold;
  }

  .pool {
    margin: 5vw 5vw;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 3vw;

    .pool_name {
      padding: 3vw 5vw;
      background-color: #f9fafc;
      color: #999999;
      font-size: 4vw;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .van-icon {
        color: #124da5;
      }
    }

    .terms {
      .item {
        width: 80vw;
        padding: 5vw 5vw;
        background-color: #ffffff;

        .text {
          color: #333333;
          font-size: 4vw;
          padding-left: 2vw;
        }

        .text:first-child {
          padding-top: 0;
        }

        .installment {
          .text {
            color: #333333;
            font-size: 4vw;
            padding-top: 2vw;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
              vertical-align: middle;
              color: #0486fe;
            }

            .van-icon {
              vertical-align: middle;
              margin-left: 2vw;
            }
          }
        }
      }
    }
  }

  .van-dialog {
    .dialog {
      margin: 0 5vw;
    }

    .cash_num {
      margin: 2vw 5vw 0;
      color: #124da5;
      font-weight: bold;
    }

    .van-cell__value {
      border-bottom: 0.1vw solid #ccc;
    }

    .validation {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .van-button {
        width: 40vw;
        margin-right: 2vw;
      }
    }
  }

  .van-popup {
    height: 60% !important;
    border-top-left-radius: 8vw;
    border-top-right-radius: 8vw;

    .title {
      text-align: center;
      margin-top: 5vw;
      margin-bottom: 10vw;
      font-size: 4.5vw;
      color: #333333;
      font-weight: bold;
    }

    .content {
      padding: 1vw 5vw;
      font-size: 4vw;
      color: #333333;
      line-height: 7vw;
    }
  }
}
</style>
