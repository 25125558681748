<template>
	<div class="trusteeship">
		<div class="second_title">
			<div class="CN">购买托管</div>
			<div class="EN">Buy trusteeship</div>
			<img width="40px" src="../../assets/img/ic_gou.png" alt="智岚博丰">
		</div>
		<div class="second_item">
			<div class="name">订单编号</div>
			<div class="erver_name">{{ruleForm.orderId}}</div>
		</div>
		<div class="second_item">
			<div class="name">托管时间</div>
			<div class="erver_name">({{feedate}}/{{date}})</div>
		</div>
		<div class="second_item" style="display: block;">
			<div class="name">付款信息</div>
			<div class="info">
				<div class="card_code">
					<div>卡号：{{card_code}}</div>
					<div class="recommend" v-clipboard:copy="card_code" v-clipboard:success="onCopy" v-clipboard:error="onError"><img width="18px" src="../../assets/img/copy_code.png" alt=""></div>
				</div>
				<div class="card_code">姓名：刘学奇</div>
				<div class="card_code">开户行：农业银行潍坊奎文东郊支行</div>
			</div>
		</div>
		<div class="upload_item">
			<van-uploader v-model="fileList" multiple :after-read="upload" max-count="6" @delete="delimage">
				<div class="upload_click">
					<img width="42.33px" src="../../assets/img/ic_tupian.png" alt="">
					<div class="text">点击上传凭证</div>
				</div>
			</van-uploader>
		</div>
		<div class="note_item">
			<van-field v-model="ruleForm.remarks" rows="2" autosize type="textarea" maxlength="1000"
				placeholder="请填写备注(选填)" show-word-limit />
		</div>
		<div class="second_item">
			<div class="name">单价</div>
			<div class="erver_name">18</div>
		</div>
		<div class="second_item">
			<div class="name">数量</div>
			<div class="erver_name">
				<van-stepper v-model="ruleForm.month" :default-value="3" min="3" max="36" step="3" @change="changeNum(ruleForm.month)"/>
			</div>
		</div>
		<div class="price">
			总计：{{ruleForm.amount}}
		</div>
		<div class="second_btn">
			<van-button color="#FF5722" @click="onPay" :disabled="disabled">提交</van-button>
		</div>
	</div>
</template>

<script>
	import {
		Buy
	} from '@/api/index';
	export default {
		data() {
			return {
				card_code:8480296778756266,
				disabled:true,
				fileList: [],
				checked: false,
				buyDetail: {},
				id: '',
				feedate:0,
				date:0,
				ruleForm: {
					orderId: '',
					proof: '',
					remarks: '',
					month:3,
					amount:0
				},
				image_urls: [],
				uploadFileList: []
			}
		},
		created() {
			this.ruleForm.orderId = this.$route.query.id
			this.feedate = this.$route.query.feedate
			this.date = this.$route.query.date
			this.ruleForm.amount = this.ruleForm.month * 18;
		},
		methods:{
			changeNum(val) {
				let that = this;
				this.ruleForm.amount = val * 18;
			},
			//上传凭证
			upload(file) {
				let images = []
				if (typeof file.length == 'undefined') {
					images = [file]
				} else {
					images = file
				}
				let that = this
				that.disabled = true;
				images.forEach(function(item) {
					file.status = 'uploading';
					file.message = '上传中...';
					let formData = new FormData();
					formData.append('file', item.file);
					Buy.ImageUpload(formData).then(res => {
						if(res.data.data.path != '') {
							file.status = 'success';
							file.message = '上传成功';
							that.disabled = false;
							that.image_urls.push(res.data.data.path)
							that.$message.success('上传成功')
						}else {
							file.status = 'failed';
							file.message = '上传失败';
							that.disabled = true;
						}
						
					}).catch(err => {})
				})
			},
			//图片移除
			delimage(file, index) {
				this.image_urls.filter((item, indexs) => {
					if (index.index == indexs) {
						this.image_urls.splice(indexs, 1); //splice 删除
					}
				});
				return true;
			},
			//提交
			async onPay() {
				// if (!this.agree) {
				// 	this.$message.error('请先勾选同意合同条款')
				// 	return
				// }
				if (this.image_urls.length == 0) {
					this.$message.error('请上传付款凭证')
					return
				}
				this.ruleForm.proof = this.image_urls
				let {
					status,
					data
				} = await Buy.buytrusteeship(this.ruleForm);
				if (status) {
					if (data.code == 0) {
						this.$message.success('操作成功')
						this.goPay(this.ruleForm.orderId)
					} else {
						this.$message.error(data.message)
					}
				}
			},
			goPay(id) {
				this.$router.push({
					path: "/Server/detail",
					query: {
						id: id
					}
				})
			},
			onCopy(e){ 　　 // 复制成功
				this.$message.success('复制成功')
			},
			onError(e){　　 // 复制失败
				this.$message.success('复制失败')
			},
		},
	}
</script>

<style lang="less">
	.trusteeship {
		.second_title {
			position: relative;
			.CN {
				text-align: center;
				margin-top: 10vw;
				font-size: 4vw;
				font-weight: bold;
				color: #333333;
			}
			.EN {
				margin-top: 2vw;
				text-align: center;
				font-size: 3vw;
				font-weight: bold;
				color: #333333;
			}
			img {
				position: absolute;
				right: 5vw;
				top: 0vw;
			}
		}
		.second_item {
			margin: 5vw;
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			padding: 5vw;
			border-radius: 3vw;
			font-size: 4vw;
			.name {
				color: #999999;
			}
			.server_name {
				color: #333333;
			}
			.info {
				font-size: 3.5vw;
				color: #333333;
				margin-top: 2vw;
				.card_code {
					display: flex;
					align-items: center;
					padding-top: 1vw;
					img {
						margin-left: 2vw;
					}
				}
			}
		}
		.upload_item {
			margin: 5vw;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			padding: 5vw 9vw;
			border-radius: 3vw;
			font-size: 4vw;
			.van-uploader {
				.upload_click {
					text-align: center;
					img {
						padding-top: 3vw;
					}
					.text {
						padding-top: 2vw;
						font-size: 3vw;
						color: #124DA5;
					}
				}
			}
		}
		.note_item {
			margin: 5vw;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			padding: 5vw 5vw;
			border-radius: 3vw;
			font-size: 4vw;
		}
		.price {
			margin: 5vw;
			color: #FF5722;
			font-size: 4vw;
			font-weight: bold;
		}
		.second_btn {
			margin: 5vw 5vw 20vw;
			.van-button:last-child {
				width: 90vw;
				border-radius: 3vw;
			}
		}
	}
</style>
