<template>
	<div class="income_detail">
		<van-nav-bar title="收益详情" />
		<div class="detail_top">
			<div class="buy_number">
				<div class="text">收益(元)</div>
				<div class="number">{{ tableData.reward }}</div>
				<div class="time">{{ tableData.createdAt }}</div>
			</div>
			<div class="reward">{{ type }}</div>
		</div>
		<div class="buy_bottom">
			<div class="line">
				<span class="title">订单编号</span>
				<span class="content">{{ tableData.Order.id }}</span>
			</div>
			<div class="line">
				<span class="title">用户编号</span>
				<span class="content">{{ tableData.Order.userId }}</span>
			</div>
			<div class="line">
				<span class="title">购买价格</span>
				<span class="content">{{ tableData.Order.ppsAmount }}</span>
			</div>
			<div class="line">
				<span class="title">购买数量</span>
				<span class="content">{{ tableData.Order.ppsOrder }}</span>
			</div>
			<div class="line">
				<span class="title">购买时间</span>
				<span class="content">{{ tableData.Order.paymentAt }}</span>
			</div>
		</div>
		<div class="allot_box" v-if="this.$store.state.base.userId == 10 || this.$store.state.base.userId == 1">
			<div class="tit">分配详情</div>
			<div class="allot">
				<div class="line">
					<span class="title">订单编号</span>
					<span class="title">收益</span>
					<span class="title">收益类型</span>
				</div>
				<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="orderDetail()">
					<div class="order_list" v-for="(item, index) in orderData">
						<div class="item">
							<div class="box">{{ item.orderId }}</div>
							<div class="box">{{ item.reward }}</div>
							<div class="box">
								<span v-if="item.rewardType == 30">PPS成本</span>
								<span v-if="item.rewardType == 31">剩余60%</span>
								<span v-if="item.rewardType == 20">固定20%</span>
								<span v-if="item.rewardType == 21">剩余40%</span>
								<span v-if="item.rewardType == 5">间推5%</span>
								<span v-if="item.rewardType == 10">直推10%</span>
								<span v-if="item.rewardType == 2005">固定5%</span>
								<span v-if="item.rewardType == 2007">固定7%</span>
								<span v-if="item.rewardType == 2008">固定8%</span>
								<span v-if="item.rewardType == 2015">固定15%</span>
							</div>
						</div>
					</div>
				</van-list>
			</div>
		</div>
	</div>
</template>

<script>
import {
	Distribution
} from "@/api/index";
export default {
	data() {
		return {
			id: '',
			orderId: '',
			tableData: {
				Order: {}
			},
			orderData: [],
			type: '',
			//刷新
			loading: false,
			finished: false,
			page: {
				current_page: 1,
				pagesize: 10
			},
		}
	},
	created() {
		let {
			id, orderId
		} = this.$route.query;
		if (this.id == '') {
			this.id = id
		}
		if (this.orderId == '') {
			this.orderId = orderId
		}
		this.Detail();
	},
	methods: {
		async Detail() {
			let {
				status,
				data
			} = await Distribution.incomedetail({
				id: this.id
			});
			if (status) {
				if (data.code == 0) {
					this.tableData = data.data.detail;
					if (data.data.detail.rewardType == 30) {
						this.type = '每PPS成本'
					} else if (data.data.detail.rewardType == 31) {
						this.type = '剩余60%'
					} else if (data.data.detail.rewardType == 20) {
						this.type = '固定20%'
					} else if (data.data.detail.rewardType == 21) {
						this.type = '剩余40%'
					} else if (data.data.detail.rewardType == 5) {
						this.type = '间推5%'
					} else if (data.data.detail.rewardType == 10) {
						this.type = '直推10%'
					} else if (data.data.detail.rewardType == 2005) {
						this.type = '固定5%'
					}
					else if (data.data.detail.rewardType == 2007) {
						this.type = '固定7%'
					}
					else if (data.data.detail.rewardType == 2008) {
						this.type = '固定8%'
					}
					else if (data.data.detail.rewardType == 2015) {
						this.type = '固定15%'
					}
				}
			}
		},
		async orderDetail() {
			if (this.$store.state.base.userId == 10 || this.$store.state.base.userId == 1) {
				if (!this.finished) {
					let {
						status,
						data
					} = await Distribution.orderdetail({
						orderId: this.orderId,
					});
					if (status) {
						if (data.code == 0) {
							if (data.data.list == null) {
								this.finished = true;
							} else {
								this.orderData.push(...data.data.list);
								this.finished = true;
								// if (this.orderData.length == data.data.total || data.data.list === null) {
								// 	this.finished = true;
								// }
								// this.page.current_page += 1
							}
						}
					}
				}
			}
		},
	}
}
</script>

<style lang="less">
.allot_box {
	padding: 0 5vw;
	min-height: 75vh;

	.tit {
		padding: 2vw 0;
		text-align: center;
		border-top: 0.2vw solid #eee;
	}

	.allot {
		.line {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 3vw 0;

			.title {
				font-size: 4vw;
				color: #999999;
				width: 30%;
				text-align: center;
			}
		}

		.order_list {
			.item {
				display: flex;
				align-items: center;
				justify-content: space-between;

				padding: 1vw;

				.box {
					width: 30%;
					text-align: center;
				}
			}
		}
	}
}

.income_detail {
	.van-nav-bar__content {
		background-color: #124DA5;

		.van-nav-bar__title {
			color: #eee;
		}
	}

	.detail_top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 0.2vw solid #eee;

		.buy_number {
			padding: 5vw 5vw;

			.time {
				font-size: 3vw;
				color: #999999;
				margin-top: 2vw;
			}

			.text {
				font-size: 3vw;
				color: #333333;
			}

			.number {
				font-size: 6vw;
				font-weight: bold;
				color: #333333;
				padding-top: 2vw;
			}
		}

		.reward {
			padding: 5vw 5vw;
			font-size: 3.5vw;
			font-weight: bold;
			color: #333333;
		}
	}


	.buy_bottom {
		margin: 2vw 5vw 0;

		.gopay {
			text-align: right;
		}

		.line {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 3vw 0;

			.title {
				font-size: 4vw;
				color: #999999;
			}

			.content {
				font-size: 4vw;
				color: #333333;
			}
		}
	}

	.buy_btn {
		margin: 30vw 5vw 0;

		.van-button {
			border-radius: 2vw;
		}

		.van-button:first-child {
			margin-right: 2vw;
			width: 33vw;
		}

		.van-button:last-child {
			width: 55vw;
		}
	}
}
</style>
