<template>
	<div class="certification">
		<div class="certification_bg"></div>
		<div class="certification_form">
			<van-cell-group>
				<van-field v-model="ruleForm.truename" label="真实姓名" placeholder="请输入真实姓名" :disabled='isDisabled' />
			</van-cell-group>
			<van-cell-group>
				<van-field v-model="ruleForm.idNo" label="身份认证" placeholder="请填写与姓名一致的证件号" :disabled='isDisabled' />
			</van-cell-group>
			<div class="font">上传证件</div>
			<div class="picture">
				<div class="front_picture">
					<div class="front" v-if="ruleForm.auditStatus != 0" style="margin-left: 5vw;">
						<a :href=" 'https://file.aleopool.top/' + ruleForm.idImgFront" target="_blank">点击查看</a>
					</div>
					<van-uploader v-else v-model="fileList1" :max-count="1" :after-read="uploadF" @delete="delimageF" />
					<div class="front_font">身份证正面</div>
				</div>
				<div class="back_picture">
					<div class="back" v-if="ruleForm.auditStatus != 0" style="margin-left: 5vw;">
						<a :href="'https://file.aleopool.top/' + ruleForm.idImgBehind" target="_blank">点击查看</a>
					</div>
					<van-uploader v-else v-model="fileList2" :max-count="1" :after-read="uploadB" @delete="delimageB" />
					<div class="back_font">身份证反面</div>
				</div>
			</div>
			<div class="note">上传证件与姓名需要一致，JPG、PNG格式图片小于5M</div>
			
			<div class="certification_btn" v-if="ruleForm.auditStatus == 0 || ruleForm.auditStatus == -10">
				<van-button color="#124DA5" @click="handleRealname()">提交</van-button>
			</div>
		</div>
		<div class="certification_form" v-if="ruleForm.auditStatus != 0">
			<div class="status" v-if="ruleForm.auditStatus == -10">审核不通过</div>
			<div class="status" v-if="ruleForm.auditStatus == 1">已提交待审核</div>
			<div class="status" v-if="ruleForm.auditStatus == 10">审核通过</div>
			<div class="reason" v-if="ruleForm.auditStatus == -10">审核失败原因:{{ruleForm.auditReason}}</div>
		</div>
	</div>
</template>

<script>
	import {Personal} from "@/api/index";
	export default {
		data() {
			return {
				isDisabled:false,
				realname: '',
				fileList1: [],
				fileList2: [],
				ruleForm: {},
				images: [],
				userform:{
					truename:'',
					idNo:'',
					idFront:'',
					idBehind:'',
				}
			};
		},
		created() {
			this.Info();
		},
		methods: {
			async Info() {
				let { status, data } = await Personal.info();
				if (status) {
					if(data.code == 0) {
						this.ruleForm = data.data;
						if(this.ruleForm.auditStatus != 0) {
							this.isDisabled = true;
						}else {
							this.isDisabled = false;
						}
					}
				}
			},
			async handleRealname() {
				this.userform.truename = this.ruleForm.truename;
				this.userform.idNo = this.ruleForm.idNo;
				let {status,data} = await Personal.realname(this.userform);
				if (status) {
					if (data.code == 0) {
						this.$message.success('操作成功');
						this.Info();
					} else {
						this.$message.error(data.message);
					}
				}
			},
			uploadF(file) {
				let images = []
				images = file
				let that = this;
				let formData = new window.FormData();
				formData.append('file', file.file)
				Personal.ImageUpload(formData).then(res => {
					that.userform.idFront = res.data.data.path;
				}).catch(err => {
				})
			},
			uploadB(file) {
				let images = []
				images = file
				let that = this;
				let formData = new window.FormData();
				formData.append('file', file.file)
				Personal.ImageUpload(formData).then(res => {
					that.userform.idBehind = res.data.data.path;
				}).catch(err => {
				})
			},
			delimageF(file){
				that.userform.idFront = '';
			},
			delimageB(file){
				that.userform.idBehind = '';
			},
		},
	}
</script>

<style lang="less">
	.certification {
		.certification_bg {
			background: url(../../assets/img/background.png) no-repeat center center;
			width: 100vw;
			height: 60vw;
		}
		.certification_form {
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
			margin: 5vw 5vw 10vw 5vw;
			border-radius: 3vw;
			padding: 6vw 4vw;
			box-sizing: border-box;

			.van-cell {
				display: block;
				font-size: 3.5vw;
				color: #333333;
			}

			.font {
				padding-left: 4vw;
				font-size: 3.5vw;
				color: #333333;
			}

			.picture {
				display: flex;

				.front_picture {
					margin: 5vw 5vw 5vw 5vw;

					.front {
						img {
							height: 20vw;
						}
					}

					.front_font {
						font-size: 3.5vw;
						margin-left: 5vw;
						color: #333333;
					}
				}

				.back_picture {
					margin: 5vw;

					.back {
						img {
							height: 20vw;
						}
					}

					.back_font {
						font-size: 3.5vw;
						margin-left: 5vw;
						color: #333333;
					}
				}
			}

			.note {
				padding-left: 4vw;
				font-size: 3.5vw;
				color: #999999;
			}
			.status {
				padding-left: 4vw;
				padding-top: 2vw;
				font-size: 3.5vw;
				color: #409eff;
			}
			.reason {
				padding-left: 4vw;
				padding-top: 2vw;
				font-size: 3.5vw;
				color: #E80000;
			}
			.certification_btn {
				margin: 5vw 0 10vw 0vw;
				width: 90vw;

				.van-button {
					width: 80vw;
					border-radius: 2vw;
				}
			}

			.van-field__control {
				border: 0.2vw solid #eee;
				padding: 1vw 3vw;
				border-radius: 2vw;
				margin-top: 2vw;
			}
		}

		[class*=van-hairline]::after {
			width: 0;
		}

		.van-uploader {
			margin: 4vw;
		}
		input{
		    -webkit-appearance: none;
		}
	}
</style>
