import axios from "axios";
//基本信息
let info = (data = {}) => axios.get("/api/v1/my/profile", { params: data });
//认证
let realname = (data = {}) => axios.post("/api/v1/my/identify", data);
//上传图片
let ImageUpload = (data = {}) => axios.post("/api/v1/upload/id", data);
let aleoWallet = (data = {}) =>
  axios.get("/api/v1/my/aleoWallet", { params: data });
let ioWallet = (data = {}) =>
  axios.get("/api/v1/my/ioWallet", { params: data });
let aleoList = (data = {}) =>
  axios.get("/api/v1/my/aleoList", { params: data });
let ioList = (data = {}) => axios.get("/api/v1/my/ioList", { params: data });
export default {
  info,
  realname,
  ImageUpload,
  aleoWallet,
  ioWallet,
  aleoList,
  ioList,
};
