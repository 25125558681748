import axios from "axios"
//登录
let login = (formData) => axios.post("/api/v1/user/sign-in",formData);
//注册
let register = (formData) => axios.post("/api/v1/user/sign-up",formData);
//退出登录
let logout = (formData={}) => axios.post("/api/v1/user/sign-out",formData);

//发送短信验证码
let sendsms = (data = {}) => axios.get('/api/v1/sms',{ params:data });
//安全信息绑定
let security = (data = {}) => axios.post("/api/v1/my/security",data);
//图形验证码
let verifyCode = (data = {}) => axios.get('/api/v1/captcha', { params:data });
//修改密码
let password = (data = {}) => axios.post("/api/v1/my/change-passwrd",data);
//获取安全手机对应的passport
let getpassport = (data = {}) => axios.post("/api/v1/user/mobile-passport",data);
//忘记密码
let forget = (data = {}) => axios.post("/api/v1/user/forget-passwrd",data);

export default{
    register,
    login,
	logout,
	sendsms,
	verifyCode,
	security,
	password,
	getpassport,
	forget,
}